import React, { useState } from "react";
import { ModalBuilder, Form, Modal } from "@redriver/cinnamon";
import { FormModal } from "components/modals";
import { FloatingEditButton } from "components/buttons";
import { editGroup, deleteGroup } from "./actions";
import { ThreeButtonModal } from "components/modals";

const EditRegistrationGroup = ({
  as: As = FloatingEditButton,
  group,
  eventId,
  onRefresh,
  ...props
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  return (
    <ModalBuilder
      withForm
      initialData={{
        name: group?.name,
        description: group?.description,
      }}
      submitAction={editGroup}
      submitParams={{ groupId: group.id, eventId }}
      onSubmitted={onRefresh}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => {
        return (
          <React.Fragment>
            <ThreeButtonModal
              {...modalProps}
              size="small"
              header="Edit Section"
              submitLabel="Update"
              cancelLabel="Cancel"
              otherSubmitLabel="Delete"
              otherSubmitIcon="trash"
              onOtherSubmit={
                group?.isCustom
                  ? () => {
                      setConfirmDelete(true);
                      return true;
                    }
                  : null
              }
            >
              <Form {...formProps}>
                {group.isCustom ? (
                  <Form.Input
                    field="name"
                    label="Section Name"
                    fluid
                    required
                  />
                ) : (
                  <Form.Field label="Name">
                    <span>{group?.name}</span>
                  </Form.Field>
                )}
                <Form.TextArea field="description" label="Description" fluid />
              </Form>
            </ThreeButtonModal>
            <ModalBuilder
              visible={!!confirmDelete}
              submitAction={deleteGroup}
              submitParams={{ groupId: group.id, eventId }}
              onSubmitted={onRefresh}
              onCancelled={() => setConfirmDelete(null)}
              renderModal={(modalProps) => (
                <Modal.Confirmation
                  {...modalProps}
                  header="Delete Registration Section"
                >
                  <p>
                    Are you sure that you wish to delete this registration
                    section?
                  </p>
                </Modal.Confirmation>
              )}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export default EditRegistrationGroup;
