import React, { useState } from "react";
import { ModalBuilder, Form, Modal } from "@redriver/cinnamon";
import { updateProfileDetails } from "./actions";
import { FloatingEditButton } from "components/buttons";
import produce from "immer";
import { ContactPointIcon, enumOptionsArray } from "constants/enums";
import { RemovableArrayRow } from "components/forms";
import ShouldUpdateFutureEventProfilesMessage from "./ShouldUpdateFutureEventProfilesMessage";

const EditInformationDetails = ({
  profile,
  setProfile,
  displayFutureProfileUpdateMessage,
  ...props
}) => {
  const [formData, setFormData] = useState(false);
  const initialData = {
    information: {
      phone: profile?.phone,
      mobile: profile?.mobile,
      contactPoints: (profile?.contactPoints || []).filter((x) => !x.readonly),
    },
  };

  const contactPointTypes = enumOptionsArray(ContactPointIcon);
  return (
    <ModalBuilder
      withForm
      initialData={initialData}
      renderTrigger={(openModal) => (
        <FloatingEditButton
          onClick={() => {
            openModal();
            setFormData(initialData);
          }}
          {...props}
        />
      )}
      submitAction={updateProfileDetails}
      onSubmitted={() => {
        const nextProfile = produce(profile, (draft) => {
          draft.phone = formData.information.phone;
          draft.mobile = formData.information.mobile;
          draft.contactPoints = [
            ...formData.information.contactPoints,
            ...(profile?.contactPoints || []).filter((x) => !!x.readonly),
          ];
        });
        setProfile(nextProfile);
      }}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Edit Profile Information">
          <Form
            {...formProps}
            onChange={(field, data, applyChanges) => {
              formProps.onChange(field, data, applyChanges);
              setFormData(applyChanges(formProps.value));
            }}
          >
            <Form.Object field="information">
              <p style={{ marginTop: 0 }}>
                Your primary email address used for correspondence and to log in
                to the portal ({profile.email}) is shown on your Account page.
                Please contact us if you need to change your primary/login
                email.
              </p>
              <Form.Group widths="equal">
                <Form.Input
                  field="phone"
                  label="Phone"
                  fluid
                  placeholder="Enter your phone number"
                />
                <Form.Input
                  field="mobile"
                  label="Mobile"
                  fluid
                  placeholder="Enter your mobile phone number"
                />
              </Form.Group>
              <Form.Array
                field="contactPoints"
                label="Other Contact Points"
                arrayKey="id"
              >
                <RemovableArrayRow columns={2}>
                  <Form.Dropdown
                    field="type"
                    options={contactPointTypes}
                    required
                    fluid
                    placeholder="Select contact point type"
                  />
                  <Form.Input
                    field="value"
                    placeholder="Enter contact point details"
                    required
                    fluid
                  />
                </RemovableArrayRow>
              </Form.Array>
              <Form.If
                condition={({ fields }) => fields.contactPoints.length == 0}
              >
                <p>None</p>
              </Form.If>
              <Form.ArrayAdder
                field="contactPoints"
                arrayKey="id"
                icon="plus"
                content="Add another contact point"
                primary
                compact
              ></Form.ArrayAdder>
            </Form.Object>
            {displayFutureProfileUpdateMessage && (
              <ShouldUpdateFutureEventProfilesMessage />
            )}
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditInformationDetails;
