import React from "react";
import classNames from "classnames";

const GeneralStatusLabel = ({
  as: As = "div",
  className,
  children = "Active",
  grey,
  inline,
  ...props
}) => (
  <As
    className={classNames("general-status-label", { grey, inline }, className)}
    {...props}
  >
    {children}
  </As>
);

export default GeneralStatusLabel;
