import { SocialMediaLinkType } from "constants/enums";

// these are regexes for validating social media profile links
export const socialMediaLinkRegexMapping = {
  [SocialMediaLinkType.Twitter]: "https?://(twitter|x)\\.com/(.+)",
  [SocialMediaLinkType.LinkedIn]: "https?://([a-z]+\\.)?linkedin\\.com/in/(.+)",
  [SocialMediaLinkType.Facebook]: "https?://facebook\\.com/(.+)",
  [SocialMediaLinkType.Instagram]: "https?://(www\\.)?instagram\\.com/(.+)",
};

// valid examples for the social media profile link regexes
export const socialMediaLinkExampleMapping = {
  [SocialMediaLinkType.Twitter]: "http://x.com/yourname",
  [SocialMediaLinkType.LinkedIn]: "http://linkedin.com/in/yourname",
  [SocialMediaLinkType.Facebook]: "http://facebook.com/your.name",
  [SocialMediaLinkType.Instagram]: "http://www.instagram.com/yourname",
};

// these regexes validate the main social media links
export const socialMediaLinkPrefillMapping = {
  [SocialMediaLinkType.Twitter]: "http://x.com/",
  [SocialMediaLinkType.LinkedIn]: "http://linkedin.com/in/",
  [SocialMediaLinkType.Facebook]: "http://facebook.com/",
  [SocialMediaLinkType.Instagram]: "http://www.instagram.com/",
};
