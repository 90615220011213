import { apiGet, apiRequests } from "@redriver/cinnamon";

const NAMESPACE = "PROFILE/VIEW_PARTICIPANT_PROFILE";

const ActionTypes = {
  GetProfileDetails: `${NAMESPACE}/GET_PROFILE_DETAILS`,
  GetProfileImages: `${NAMESPACE}/GET_PROFILE_IMAGES`,
};

const getProfileDetails = (participantId) =>
  apiGet(
    ActionTypes.GetProfileDetails,
    `profile/public/participant/${participantId}`
  );

const getProfileImages = (participantId) =>
  apiGet(
    ActionTypes.GetProfileImages,
    `profile/public/participant/${participantId}/images`
  );

export const getPublicProfile = (participantId) =>
  apiRequests({
    images: getProfileImages(participantId),
    profile: getProfileDetails(participantId),
  });
