export const ProfileHeaderImage = {
  minHeight: 200,
  minWidth: 800,
  aspect: 20 / 5,
};

export const ProfileUserImage = {
  minHeight: 100,
  minWidth: 100,
  aspect: 1 / 1,
};
