import React, { useState } from "react";
import { Comment, Input } from "semantic-ui-react";
import { useAuthClaims } from "@redriver/cinnamon";
import { UserIcon } from "../icons";

const AddComment = ({ disabled, onAdd }) => {
  const [value, setValue] = useState("");
  const { userName } = useAuthClaims();

  const add = () => {
    if (disabled || value.length === 0) return;
    onAdd(value);
    setValue("");
  };

  return (
    <Comment className="add-comment">
      <UserIcon userName={userName} />
      <Comment.Content>
        <Input
          value={value}
          onChange={(e, d) => setValue(d.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") add();
          }}
          placeholder="Add a comment..."
          action={
            <div className="post-action">
              <a onClick={add}>POST</a>
            </div>
          }
          disabled={disabled}
        />
      </Comment.Content>
    </Comment>
  );
};

export default AddComment;
