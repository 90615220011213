import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Form, Modal } from "@redriver/cinnamon";
import { ProfileImageType } from "constants/enums";
import { ThreeButtonModal } from "components/modals";
import { ImageCropper } from "components/forms";

const ResetImage = (props) => <a {...props}>Choose another file</a>;

const AddProfileImage = ({
  profileImageType = ProfileImageType.User,
  updateAction,
  deleteAction,
  submitParams = {},
  as: As = Button,
  onUpdated,
  minHeight,
  minWidth,
  maxAspect,
  minAspect,
  onOpened,
  onClosed,
  ...props
}) => {
  const [confirmDelete, setConfirmDelete] = useState(null);

  return (
    <ModalBuilder
      withForm
      submitAction={updateAction}
      submitParams={{
        profileImageType,
        ...submitParams,
      }}
      onSubmitted={() => {
        if (onUpdated) onUpdated();
        if (onClosed) onClosed();
      }}
      onCancelled={onClosed}
      renderTrigger={(showModal) => (
        <As
          onClick={() => {
            showModal();
            if (onOpened) onOpened();
          }}
          {...props}
        />
      )}
      renderModal={(modalProps, formProps, state, events) => {
        return (
          <React.Fragment>
            <ThreeButtonModal
              {...modalProps}
              header={`Add ${
                profileImageType == ProfileImageType.Background
                  ? "Background"
                  : "Profile"
              } Image`}
              submitLabel="Add"
              cancelLabel="Cancel"
              otherSubmitLabel="Delete"
              otherSubmitIcon="trash"
              onOtherSubmit={() => {
                setConfirmDelete(true);
                return true;
              }}
              size="small"
            >
              <Form {...formProps}>
                {formProps.value.image ? (
                  <React.Fragment>
                    <ImageCropper
                      field="crop"
                      file={formProps.value.image}
                      minHeight={minHeight || 100}
                      minWidth={minWidth || 100}
                      maxAspect={maxAspect || 1 / 1}
                      minAspect={minAspect || 1 / 1}
                      fluid
                      required
                    />
                    <Form.Clearer as={ResetImage} />
                  </React.Fragment>
                ) : (
                  <Form.FileUpload
                    field="image"
                    fluid
                    required
                    uploadText="Drag and drop an image file here or click to upload"
                    uploadIcon={
                      <Button
                        icon="upload"
                        content="Upload"
                        style={{ marginBottom: "0.25em" }}
                      />
                    }
                    fileExtensionTypes={["png", "jpg", "jpeg"]}
                    fileTypesError="File must be an image file of type PNG or JPG"
                  />
                )}
              </Form>
            </ThreeButtonModal>

            <ModalBuilder
              visible={!!confirmDelete}
              submitAction={deleteAction}
              submitParams={{
                profileImageType,
                ...submitParams,
              }}
              onCancelled={() => setConfirmDelete(false)}
              onSubmitted={() => {
                setConfirmDelete(false);
                if (onUpdated) onUpdated();
                if (onClosed) onClosed();
                events.onCancel();
              }}
              renderModal={(modalProps) => (
                <Modal.Confirmation
                  {...modalProps}
                  header={`Delete ${
                    profileImageType === ProfileImageType.Background
                      ? "Background"
                      : "Profile"
                  } Image`}
                  submitLabel="Delete"
                  cancelLabel="Cancel"
                  size="tiny"
                >
                  Are you sure you wish to delete the current{" "}
                  {profileImageType === ProfileImageType.Background
                    ? "background"
                    : "profile"}{" "}
                  image?
                </Modal.Confirmation>
              )}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export default AddProfileImage;
