import React from "react";
import { Wizard, lookupReset, notifySuccess } from "@redriver/cinnamon";
import { saveSection, setSaving } from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { getSaving, getUnlockedToIndex } from "./selectors";
import { LinkButton } from "components/buttons";
import { currentUserProfileLookup } from "features/Profile/lookups";

const StepSave = ({
  className,
  formData,
  eventId,
  participantId,
  saveSectionAction,
}) => {
  const dispatch = useDispatch();
  const saving = useSelector(getSaving);
  const unlockedToIndex = useSelector((s) => getUnlockedToIndex(s));
  return (
    <Wizard.Nav
      render={({
        steps,
        stepKey,
        activeStep,
        activeStepIndex,
        hasNextStep,
        hasPreviousStep,
        hasStep,
        onGoToNextStep,
        onGoToPreviousStep,
        onGoToStep,
        hidePrevious,
        hideNext,
      }) =>
        activeStepIndex < unlockedToIndex ? null : (
          <LinkButton
            className={className}
            onClick={() => {
              if (!saving) {
                dispatch(
                  saveSection(formData, {
                    eventId,
                    sectionId: activeStep.key,
                    participantId,
                    canProgress: false,
                    saveSectionAction,
                  })
                ).then(() => {
                  dispatch(lookupReset(currentUserProfileLookup));
                  dispatch(
                    notifySuccess("Registration progress has been saved")
                  );
                });
              }
            }}
          >
            Save & Continue Later
          </LinkButton>
        )
      }
    />
  );
};

export default StepSave;
