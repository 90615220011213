import { useLookup, debounce } from "@redriver/cinnamon";
import React, { useCallback } from "react";
import { Segment } from "semantic-ui-react";
import { programmeThemeLookup } from "modules/lookups";
import classNames from "classnames";

const ThemesLegend = ({ onHover, activeThemeIds = [], ...props }) => {
  const [{ response }] = useLookup(programmeThemeLookup);
  const notifiedHoveredId = useCallback(
    debounce((id) => onHover(id), 200),
    [onHover]
  );

  if (!Array.isArray(response)) {
    return null;
  }

  return (
    <Segment className="themes-legend" {...props}>
      <header className="legend-header">Themes</header>
      {response.map((x) => (
        <div
          className={classNames("legend-item", {
            faded:
              Array.isArray(activeThemeIds) &&
              activeThemeIds.length > 0 &&
              activeThemeIds.every((t) => t != x.value),
          })}
          key={x.value}
          onMouseEnter={() => notifiedHoveredId(x.value)}
          onMouseLeave={() => notifiedHoveredId(null)}
        >
          <div className="colour" style={{ backgroundColor: x.colour }}></div>
          <p className="text">{x.text}</p>
        </div>
      ))}
    </Segment>
  );
};

export default ThemesLegend;
