import React, { useState } from "react";
import { ModalBuilder, Form, Modal } from "@redriver/cinnamon";
import { updateProfileDetails } from "./actions";
import { FloatingEditButton } from "components/buttons";
import produce from "immer";
import { CountryDropdown } from "components/forms";
import ShouldUpdateFutureEventProfilesMessage from "./ShouldUpdateFutureEventProfilesMessage";

const EditLocationDetails = ({
  profile,
  setProfile,
  displayFutureProfileUpdateMessage,
  ...props
}) => {
  const [formData, setFormData] = useState(false);
  const initialData = {
    location: {
      town: profile?.town,
      countryDescription: profile?.countryDescription,
      countryId: profile?.countryId,
      citizenship: profile?.citizenship,
      citizenshipId: profile?.citizenshipId,
      secondaryCitizenship: profile?.secondaryCitizenship ?? null,
      secondaryCitizenshipId: profile?.secondaryCitizenshipId ?? null,
    },
  };

  return (
    <ModalBuilder
      withForm
      initialData={initialData}
      renderTrigger={(openModal) => (
        <FloatingEditButton
          onClick={() => {
            openModal();
            setFormData(initialData);
          }}
          {...props}
        />
      )}
      submitAction={updateProfileDetails}
      onSubmitted={() => {
        const nextProfile = produce(profile, (draft) => {
          draft.town = formData.location.town;
          draft.countryId = formData.location.countryId;
          draft.countryDescription = formData.location.countryId
            ? formData.location.countryOption?.name ??
              formData.location.countryDescription
            : null;
          draft.citizenshipId = formData.location.citizenshipId ?? null;
          draft.citizenship = formData.location.citizenshipId
            ? formData.location.citizenshipOption?.name ??
              formData.location.citizenship
            : null;
          draft.secondaryCitizenship = formData.location.secondaryCitizenshipId
            ? formData.location.secondaryCitizenshipOption?.name ??
              formData.location.secondaryCitizenship
            : null;
          draft.secondaryCitizenshipId =
            formData.location.secondaryCitizenshipId ?? null;
        });
        setProfile(nextProfile);
      }}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Edit Profile Location">
          <Form
            {...formProps}
            onChange={(field, data, applyChanges) => {
              formProps.onChange(field, data, applyChanges);
              setFormData(applyChanges(formProps.value));
            }}
          >
            <Form.Object field="location">
              <Form.Group widths="equal">
                <Form.Input field="town" label="Town or City" fluid required />
                <CountryDropdown
                  field="countryId"
                  textField="country"
                  optionField="countryOption"
                  placeholder="Select Country"
                  fluid
                  required
                  clearable
                  showPolicyInfo
                />
              </Form.Group>

              <Form.Group widths="equal">
                <CountryDropdown
                  field="citizenshipId"
                  textField="citizenship"
                  label="Citizenship Country"
                  optionField="citizenshipOption"
                  lookupParams={{
                    includePreferNotToSay: true,
                  }}
                  clearable
                  placeholder="Select Citizenship Country"
                  search
                  fluid
                  showPolicyInfo
                />
                <CountryDropdown
                  field="secondaryCitizenshipId"
                  textField="secondaryCitizenship"
                  label="Secondary Citizenship Country"
                  optionField="secondaryCitizenshipOption"
                  lookupParams={{
                    includePreferNotToSay: true,
                  }}
                  placeholder="Select Secondary Citizenship Country"
                  search
                  clearable
                  fluid
                  showPolicyInfo
                />
              </Form.Group>
            </Form.Object>
            {displayFutureProfileUpdateMessage && (
              <ShouldUpdateFutureEventProfilesMessage />
            )}
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditLocationDetails;
