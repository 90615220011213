import React from "react";
import { Popup, Icon } from "semantic-ui-react";
import classNames from "classnames";

const SubThemesSummary = ({ disabled, subThemes, ...props }) => {
  if (!Array.isArray(subThemes) || subThemes.length == 0) return null;

  return (
    <Popup
      position="bottom center"
      trigger={
        <div className={classNames("sub-themes", { disabled })}>
          <Icon name="trophy" />
          {subThemes.length}
        </div>
      }
      inverted
      content={
        <div style={{ display: "flex", flexDirection: "column" }}>
          {subThemes.map((x) => (
            <div key={x.value}>{x.text}</div>
          ))}
        </div>
      }
    />
  );
};

export default SubThemesSummary;
