import React, { useState, useEffect } from "react";
import { PageLoader, useLookup } from "@redriver/cinnamon";
import { updatePrivacySettings } from "./actions";
import { IconStack } from "components/icons";
import { SocialMediaLink } from "components/navigation";
import { Profile } from "components/profile";
import { useDispatch } from "react-redux";
import {
  buildLocationItems,
  buildSocialMediaItems,
  buildContactPoints,
  calcContactPointChanges,
  calcSocialMediaItemChanges,
} from "modules/helpers/profile";
import { currentUserProfileLookup } from "../lookups";
import { PrivacyToggle } from "components/profile";
import { SubThemesSummary } from "components/themes";
import { MessageBox } from "components/containers";
import InfoIcon from "assets/icons/information.svg";

const EditPrivacySettings = () => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({});
  const [profileUpdated, setProfileUpdated] = useState(false);

  const [{ loading, response }, resetLookup] = useLookup(
    currentUserProfileLookup
  );

  useEffect(() => {
    if (response) setProfile(response);
  }, [response]);

  useEffect(() => {
    return () => profileUpdated && resetLookup();
  }, [profileUpdated]);

  const saveSettings = (data) => {
    dispatch(updatePrivacySettings(data));
    setProfileUpdated(true);
  };

  const socialMediaItems = buildSocialMediaItems(profile);
  const locationItems = buildLocationItems(profile, true);
  const contactPoints = buildContactPoints(profile);

  if (loading) return <PageLoader />;

  return (
    <Profile className="privacy">
      <MessageBox title="Information" icon={InfoIcon}>
        <p>
          Switch on the data you would like other participants to see.
          <br />
          Any changes to your details can be made on your My Public Profile
          option.
        </p>
      </MessageBox>
      <Profile.Section>
        <Profile.Name profile={profile} />
        {socialMediaItems.map((m) => (
          <SocialMediaLink
            key={m.id}
            type={m.type}
            value={m.value}
            disabled={!m.isPublic}
          >
            <PrivacyToggle
              checked={m.isPublic}
              onUpdated={(checked) => {
                const { changeset, nextProfile } = calcSocialMediaItemChanges(
                  m,
                  profile,
                  checked
                );
                setProfile(nextProfile);
                saveSettings(changeset);
              }}
            />
          </SocialMediaLink>
        ))}
        <Profile.JobTitle
          profile={profile}
          style={{ display: "flex", margin: "0.5rem 0" }}
        />
        {(profile?.themes ?? []).length > 0 && (
          <Profile.Themes>
            {profile.themes.map((x) => (
              <Profile.Theme
                key={x.value}
                themeId={x.value}
                disabled={!profile?.isThemesPublic}
              />
            ))}
            <SubThemesSummary
              subThemes={profile.subThemes}
              disabled={!profile?.isThemesPublic}
            />
            <PrivacyToggle
              field="isThemesPublic"
              data={profile}
              setData={setProfile}
              onUpdated={(checked) => saveSettings({ isThemesPublic: checked })}
            />
          </Profile.Themes>
        )}
      </Profile.Section>

      <Profile.Section>
        <IconStack title="Location" items={locationItems} />
        <IconStack
          title="Contact Information"
          items={contactPoints.map((item) => ({
            ...item,
            children: (
              <PrivacyToggle
                checked={item.isPublic}
                onUpdated={(checked) => {
                  const { nextProfile, changeset } = calcContactPointChanges(
                    item,
                    profile,
                    checked
                  );
                  setProfile(nextProfile);
                  saveSettings(changeset);
                }}
              />
            ),
          }))}
        />
      </Profile.Section>

      <Profile.Section>
        <Profile.Biography profile={profile}>
          <PrivacyToggle
            field="isBiographyPublic"
            data={profile}
            setData={setProfile}
            onUpdated={(checked) =>
              saveSettings({ isBiographyPublic: checked })
            }
          />
        </Profile.Biography>
      </Profile.Section>
    </Profile>
  );
};

export default EditPrivacySettings;
