import React from "react";
import { Header, Divider } from "semantic-ui-react";
import { UserDetails, PasswordChange } from "features/System";

const MyAccountPage = () => (
  <React.Fragment>
    <UserDetails />
  </React.Fragment>
);

export default MyAccountPage;
