import React from "react";
import { Comment } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";
import { UserIcon } from "../icons";
import UserCommentDelete from "./UserCommentDelete";

const UserComment = ({ user, date, message, children }) => (
  <Comment className="user-comment">
    <UserIcon userName={user?.name} />
    <Comment.Content>
      <Comment.Author>{user?.name}</Comment.Author>
      <Comment.Metadata>
        <Format.DateTime
          value={date}
          format={({ isToday, isYesterday }) => {
            if (isToday) return "[Today at] h:mma";
            if (isYesterday) return "[Yesterday at] h:mma";
            return "h:mma Do MMM YYYY";
          }}
        />
      </Comment.Metadata>
      <Comment.Text>{message}</Comment.Text>
      {children}
    </Comment.Content>
  </Comment>
);

UserComment.Delete = UserCommentDelete;

export default UserComment;
