import React from "react";
import classNames from "classnames";
import { Icon } from "semantic-ui-react";
import { formatProgrammeDate } from "modules/helpers";

const ProgrammeDate = ({
  date,
  editable,
  onEdit,
  className,
  children,
  ...props
}) => {
  const { dayOfWeek, dayOfMonth, month } = formatProgrammeDate(date);
  return (
    <div
      {...props}
      className={classNames("programme-date", { editable }, className)}
    >
      <div className="date-label" onClick={editable ? onEdit : undefined}>
        <span className="day-of-week">{dayOfWeek}</span>
        <span className="day-of-month">{dayOfMonth}</span>
        <span className="month">{month}</span>
        {editable && <Icon name="pencil" className="edit" />}
      </div>
      <div className="date-content">{children}</div>
    </div>
  );
};

export default ProgrammeDate;
