import {
  Wizard,
  useResponsive,
  useLookup,
  PageLoader,
} from "@redriver/cinnamon";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { ScreenBreakpointNames } from "constants/screenBreakpoints";
import RegistrationNav from "./RegistrationNav";
import ProgressStep from "./ProgressStep";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnswers,
  setRegistrationTemplate,
  setEditMode,
  clearRegistrationState,
  calcRegistrationFullyCompleted,
} from "./actions";
import { registrationEventDetailsLookup } from "./lookups";
import { useUserType } from "components/auth";

const RegistrationControl = ({
  eventId,
  participantId = null,
  template,
  answerSet = null,
  saveSectionAction,
  className,
  progressPrefix,
  adminMode = false,
  onRefresh,
  loading,
  isPublished,
  formDisabled,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isExternal } = useUserType();
  useLookup(
    registrationEventDetailsLookup,
    { eventId, isExternal },
    { resetOnUnmount: true, cacheLimit: 1 }
  );
  const steps = useMemo(
    () =>
      template?.sections
        .filter(
          (s) => s.groups.length > 0 && s.groups.some((g) => g.items.length > 0)
        )
        .map((s) => ({
          key: s.id,
          title: s.name,
          hasInputItems: s.hasInputItems,
          hasGroups: s.groups.length > 0,
        })),
    [template]
  );
  const [currentStepIndex, setCurrentStepIndex] = useState(null);

  useEffect(() => {
    if (answerSet) {
      dispatch(setAnswers(answerSet));
      dispatch(calcRegistrationFullyCompleted());
    }
  }, [answerSet]);

  useEffect(() => {
    if (template) {
      dispatch(setRegistrationTemplate(template, !!adminMode));
      dispatch(calcRegistrationFullyCompleted());
    }
  }, [template, adminMode]);

  useEffect(() => {
    dispatch(setEditMode(!!adminMode, !!isPublished));
  }, [adminMode, isPublished]);

  useEffect(() => {
    if (currentStepIndex == null && steps?.length > 0) {
      setCurrentStepIndex(0);
    }
  }, [steps, currentStepIndex]);

  useEffect(() => {
    return () => dispatch(clearRegistrationState);
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <article
      className={classNames("participant-registration", className)}
      {...props}
    >
      <WizardWrapper>
        <Wizard
          steps={steps}
          step={currentStepIndex}
          onStepChange={(nextStepIndex) => setCurrentStepIndex(nextStepIndex)}
        >
          <div className="progress-wrapper">
            {progressPrefix}
            <Wizard.Nav
              component={(props) => (
                <RegistrationNav
                  {...props}
                  eventId={eventId}
                  participantId={participantId}
                  saveSectionAction={saveSectionAction}
                />
              )}
            />
          </div>
          {steps.map((s, i) => (
            <Wizard.Step
              key={s.key}
              step={s.key}
              render={(wizProps) => (
                <ProgressStep
                  eventId={eventId}
                  participantId={participantId}
                  saveSectionAction={saveSectionAction}
                  onRefresh={onRefresh}
                  disabled={formDisabled}
                  {...wizProps}
                />
              )}
            />
          ))}
        </Wizard>
      </WizardWrapper>
    </article>
  );
};

const WizardWrapper = ({ children }) => {
  const { screenSize } = useResponsive();
  const isSmall = screenSize != ScreenBreakpointNames.Desktop;
  return (
    <div className={classNames("wizard-wrapper", { collapsed: isSmall })}>
      {children}
    </div>
  );
};

export default RegistrationControl;
