import React, { useEffect } from "react";
import { ProgressNav } from "components/navigation";
import { useResponsive } from "@redriver/cinnamon";
import { ScreenBreakpointNames } from "constants/screenBreakpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnlockedToIndex,
  getSectionFormValidationState,
  getSectionFormData,
  registrationFullyComplete,
  currentRegistrationStepFormIsDirty,
} from "./selectors";
import {
  setUnlockedToIndex,
  saveSection,
  setShowValidationErrors,
} from "./actions";
import { Icon } from "semantic-ui-react";

const RegistrationNav = ({
  steps,
  activeStep,
  onGoToStep,
  prefix,
  children,
  saveSectionAction,
  eventId,
  participantId,
  ...props
}) => {
  const dispatch = useDispatch();
  const unlockedToIndex = useSelector(getUnlockedToIndex);
  const stepFormValid = useSelector((s) =>
    getSectionFormValidationState(s, activeStep.key)
  );
  const { screenSize } = useResponsive();
  const isSmall = screenSize != ScreenBreakpointNames.Desktop;
  const isFullyComplete = useSelector(registrationFullyComplete); //useRegistrationFullyComplete();
  const stepFormData = useSelector((s) =>
    getSectionFormData(s, activeStep.key)
  );
  const currentFormIsDirty = useSelector(currentRegistrationStepFormIsDirty);
  useEffect(() => {
    const activeIndex = steps.findIndex((s) => s.key == activeStep.key);
    if (unlockedToIndex == null || activeIndex > unlockedToIndex) {
      dispatch(setUnlockedToIndex(activeIndex));
    }
  }, [activeStep, unlockedToIndex]);

  return (
    <React.Fragment>
      <ProgressNav horizontal={isSmall}>
        {steps.map((x, i) =>
          x.hasGroups ? (
            <ProgressNav.Item
              key={x.key}
              name={x.title}
              selected={x == activeStep}
              completed={
                (unlockedToIndex != null && i <= unlockedToIndex) || i == 0
              }
              locked={
                (unlockedToIndex != null && i > unlockedToIndex + 1) ||
                (unlockedToIndex == null && i > 1)
              }
              onClick={async () => {
                const activeIndex = steps.findIndex(
                  (s) => s.key == activeStep.key
                );
                if (i <= unlockedToIndex || unlockedToIndex == null) {
                  if (
                    activeIndex < unlockedToIndex &&
                    currentFormIsDirty &&
                    saveSectionAction
                  ) {
                    // previously completed step
                    if (!stepFormValid) {
                      dispatch(setShowValidationErrors(true));
                    } else {
                      // auto save already completed step
                      await dispatch(
                        saveSection(stepFormData, {
                          eventId,
                          sectionId: activeStep.key,
                          participantId,
                          canProgress: true,
                          saveSectionAction,
                        })
                      );
                      onGoToStep(x.key);
                    }
                  } else {
                    onGoToStep(x.key);
                  }
                }
              }}
              className={
                isFullyComplete && i == steps.length - 1 && saveSectionAction
                  ? "finished"
                  : null
              }
              icon={
                isFullyComplete &&
                i == steps.length - 1 &&
                saveSectionAction ? (
                  <Icon name="check" />
                ) : null
              }
            />
          ) : null
        )}
      </ProgressNav>
    </React.Fragment>
  );
};

export default RegistrationNav;
