import React from "react";
import classNames from "classnames";
import { HtmlContent } from "../../containers";

const ProgrammeItem = ({
  programmeItem,
  draft,
  placeholder,
  className,
  children,
  onClick,
  ...props
}) => (
  <div
    {...props}
    className={classNames(
      "programme-item",
      {
        draft,
        placeholder: placeholder && !children,
        selectable: onClick,
      },
      className
    )}
    onClick={onClick}
  >
    {children ||
      (!placeholder && programmeItem ? (
        <HtmlContent resources={programmeItem.resources}>
          {programmeItem.content}
        </HtmlContent>
      ) : (
        placeholder
      ))}
  </div>
);

export default ProgrammeItem;
