import React from "react";

const YesNoTbcLabel = ({ value }) => {
  switch (value) {
    case true:
      return <span>Yes</span>;
    case false:
      return <span>No</span>;
    default:
      return <span>TBC</span>;
  }
};

export default YesNoTbcLabel;
