import React, { useCallback } from "react";
import { Form, useLookup, useFormState, Format } from "@redriver/cinnamon";
import { EventFormat, RegistrationItemTypes } from "constants/enums";
import { CustomErrors } from "components/forms";
import RegistrationLabel from "./RegistrationLabel";
import { useSelector } from "react-redux";
import {
  getAllQuestions,
  getParticipantIsVirtualAttendance,
} from "./selectors";
import {
  AccommodationAttendanceFields,
  useFormDisabled,
} from "components/forms";
import { registrationEventDetailsLookup } from "./lookups";
import { useUserType } from "components/auth";

const dateFormat = "dddd D MMMM YYYY";

const RegistrationAttendanceDates = ({
  item,
  isInEditMode,
  eventId,
  participantId,
}) => {
  const disabled = useFormDisabled();
  const { isExternal } = useUserType();
  const [{ response }] = useLookup(registrationEventDetailsLookup, {
    eventId,
    isExternal,
  });
  const { fields } = useFormState();
  const questions = useSelector(getAllQuestions);
  const relatedQuestionIds = questions.filter(
    (x) => x.type == RegistrationItemTypes.AccommodationRequired
  );
  const isVirtualAttendance =
    useSelector(getParticipantIsVirtualAttendance) ||
    response?.format === EventFormat.Virtual;
  const hasRequestedAccommodatation =
    isInEditMode || relatedQuestionIds.some((x) => fields[x.id] == true);

  const validate = useCallback(
    ({ fields }) => {
      if (item.required) {
        const hasValue =
          !!fields[item.id]?.arrivalDate ||
          !!(
            Array.isArray(fields[item.id]?.selectedDates) &&
            fields[item.id].selectedDates.length > 0
          );
        if (!hasValue) {
          return ["An arrival date is required"];
        }
      }
      return [];
    },
    [item]
  );

  return (
    <div>
      <Form.Object field={item.id} propagateUpdates="always">
        <Form.Field
          required={item.required}
          label={
            <RegistrationLabel
              label={
                isVirtualAttendance
                  ? "Please indicate which dates you will participate"
                  : item.label
              }
              subHeader={!isVirtualAttendance ? item.subHeading : null}
              infoText={item.infoText}
            />
          }
        ></Form.Field>

        {response && (
          <div style={{ marginBottom: "0.5em" }}>
            {!response.endDate || response.endDate == response.startDate ? (
              <React.Fragment>
                The event date is{" "}
                <Format.Date value={response.startDate} format={dateFormat} />.
              </React.Fragment>
            ) : (
              <React.Fragment>
                The event dates are{" "}
                <Format.Date value={response.startDate} format={dateFormat} />{" "}
                to <Format.Date value={response.endDate} format={dateFormat} />.
              </React.Fragment>
            )}
          </div>
        )}

        <div
          style={{ maxWidth: hasRequestedAccommodatation ? "24em" : "100%" }}
        >
          <AccommodationAttendanceFields
            eventStartDate={response?.startDate}
            eventEndDate={response?.endDate}
            asRange={hasRequestedAccommodatation}
            asPicker={!hasRequestedAccommodatation}
            allowToggleAll
            disabled={disabled}
          />
        </div>

        {response?.arrivalNotes && !isVirtualAttendance && (
          <Form.Field label="Your Arrival" style={{ marginTop: "1em" }}>
            {response.arrivalNotes}
          </Form.Field>
        )}

        {response?.departureNotes && !isVirtualAttendance && (
          <Form.Field label="Your Departure" style={{ marginTop: "1em" }}>
            {response.departureNotes}
          </Form.Field>
        )}
      </Form.Object>
      <CustomErrors
        field={item.id}
        triggerFields={[item.id]}
        validator={validate}
      />
    </div>
  );
};

export default RegistrationAttendanceDates;
