import {
  apiGet,
  apiPut,
  mockSuccess,
  apiRequest,
  apiDelete,
} from "@redriver/cinnamon";

const NAMESPACE = "PROFILE/EDIT_PROFILE_HEADER";

const ActionTypes = {
  UpdateProfileImage: `${NAMESPACE}/UPDATE_PROFILE_IMAGE`,
  DeleteProfileImage: `${NAMESPACE}/DELETE_PROFILE_IMAGE`,
};

export const updateProfileImage = ({ image, crop }, { profileImageType }) => {
  const payload = {
    type: profileImageType,
    image,
    ...crop,
  };

  const formData = new FormData();
  Object.keys(payload).forEach((k) => {
    formData.append(k, payload[k]);
  });

  return apiRequest({
    type: ActionTypes.UpdateProfileImage,
    method: "PUT",
    path: `profile/images`,
    contentType: null,
    body: formData,
  });
};

export const deleteProfileImage = ({ profileImageType }) =>
  apiDelete(ActionTypes.DeleteProfileImage, `profile/images`, {
    type: profileImageType,
  });
