import React from "react";
import { MessageBox } from "components/containers";
import { Image } from "semantic-ui-react";
import EmailIcon from "assets/icons/email-blue.svg";
import PhoneIcon from "assets/icons/phone-call.svg";
import { getAllQuestions } from "./selectors";
import { useSelector } from "react-redux";
import { RegistrationItemTypes } from "constants/enums";
import { useFormState } from "@redriver/cinnamon";
import { ExternalLink } from "components/navigation";
import { ExternalUrls } from "constants/urls";

const TravelInfo = ({ forceShow }) => {
  const { fields } = useFormState();
  const questions = useSelector(getAllQuestions);
  const relatedQuestionIds = questions.filter(
    (x) =>
      x.type == RegistrationItemTypes.TravelBookingFlightAssistance ||
      x.type == RegistrationItemTypes.TravelBookingLocalTravelAssistance
  );
  // should only display when has answered yes to questions
  const hasAgreed =
    forceShow || relatedQuestionIds.some((x) => fields[x.id] == true);
  if (!hasAgreed) {
    return null;
  }

  return (
    <MessageBox className="travel-info">
      <p style={{ marginBottom: "0.25rem" }}>
        For more information, please read the{" "}
        <ExternalLink href={ExternalUrls.WiltonPark.TravelPolicy}>
          Wilton Park Travel Policy
        </ExternalLink>
      </p>
    </MessageBox>
  );
};
export default TravelInfo;
