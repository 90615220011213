import React from "react";
import { Form } from "@redriver/cinnamon";
import RegistrationLabel from "./RegistrationLabel";
import { getIsEditMode } from "./selectors";
import { useSelector } from "react-redux";
import ExtraRegistrationItemContent from "./ExtraRegistrationItemContent";

const RegistrationFormField = ({
  item,
  as: As = Form.Input,
  extraLabelContent,
  ...props
}) => {
  const isInEditMode = useSelector(getIsEditMode);
  return (
    <As
      field={item.id}
      requiredError={`${item.label || "Field"} is required`}
      label={
        <RegistrationLabel
          label={item.label}
          subHeader={item.subHeading}
          infoText={item.infoText}
          labelPopupContent={
            isInEditMode ? <ExtraRegistrationItemContent item={item} /> : null
          }
          extraLabelContent={extraLabelContent}
        />
      }
      fluid
      required={item.required}
      {...props}
    />
  );
};

export default RegistrationFormField;
