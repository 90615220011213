import React from "react";
import { useLookup, TriggerBuilder } from "@redriver/cinnamon";
import { currentUserProfileLookup } from "../lookups";
import { Button } from "semantic-ui-react";
import { confirmPrivacySettings } from "./actions";
import { Segment } from "semantic-ui-react";
import WarningIcon from "assets/icons/warning.svg";
import { MessageBox } from "components/containers";

const ConfirmPrivacySettings = () => {
  const [{ loading, response }, resetLookup] = useLookup(
    currentUserProfileLookup
  );

  if (loading || !response || response.confirmedPrivacySettingsUtc) return null;

  return (
    <MessageBox
      className="confirm-privacy"
      icon={WarningIcon}
      title="Confirm Privacy Settings"
    >
      <p>
        Your privacy is important to us. We may share your details with other
        participants at the events that you attend.
      </p>
      <p>
        Please review and update your privacy setting to confirm that you are
        happy for us to show the data from your profile. These settings can be
        updated at any time and can be tailored per event at the point of
        registration.
      </p>
      <TriggerBuilder
        submitAction={confirmPrivacySettings}
        onSubmitted={resetLookup}
        renderTrigger={(fire, state, events) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              primary
              compact
              onClick={fire}
              loading={state.submitting}
              disabled={state.submitting}
            >
              Confirm Privacy Settings
            </Button>
          </div>
        )}
      />
    </MessageBox>
  );
};

export default ConfirmPrivacySettings;
