import React from "react";
import { Form as SForm } from "semantic-ui-react";
import { produce } from "immer";
import classNames from "classnames";

// allow this to optionally work via a specified field name
const PrivacyToggle = ({
  field,
  data,
  checked,
  setData,
  className,
  onUpdated,
  ...props
}) => {
  const css = classNames("privacy-toggle", className);

  return (
    <SForm.Checkbox
      className={css}
      checked={field ? !!data[field] : checked}
      onChange={(_, { checked }) => {
        if (field && data && setData && data[field] != checked) {
          const next = produce(data, (draft) => {
            draft[field] = checked;
          });
          setData(next);
        }
        if (onUpdated) onUpdated(checked);
      }}
      toggle
      onClick={(ev) => ev.preventDefault()}
      {...props}
    />
  );
};
export default PrivacyToggle;
