import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "PROFILE";

const ActionTypes = {
  GetProfile: `${NAMESPACE}/GET_PROFILE`,
  HasFutureEventProfiles: `${NAMESPACE}/HAS_FUTURE_EVENT_PROFILES`,
};

export const getProfile = () => {
  return apiGet(ActionTypes.GetProfile, `profile`);
};

export const hasFutureEventProfiles = () =>
  apiGet(
    ActionTypes.HasFutureEventProfiles,
    `profile/has-future-event-profile`
  );
