import React from "react";
import { ContentBuilder } from "@redriver/cinnamon";
import { IconStack } from "components/icons";
import { SocialMediaLink } from "components/navigation";
import { Profile } from "components/profile";
import { ProfileImageType } from "constants/enums";
import { getPublicProfile } from "./actions";
import {
  buildSocialMediaItems,
  buildLocationItems,
  buildContactPoints,
} from "modules/helpers/profile";
import { SubThemesSummary } from "components/themes";

const ViewParticipantProfile = ({ participantId }) => (
  <ContentBuilder
    loadAction={getPublicProfile}
    loadParams={participantId}
    loadSlowDelay={500}
    renderContent={({ images = [], profile = {} }, state) => {
      if (state.loading) return null;

      const userImage = images?.find((x) => x.type == ProfileImageType.User)
        ?.originalUrl;
      const backgroundImage = images?.find(
        (x) => x.type == ProfileImageType.Background
      )?.originalUrl;

      const locationItems = buildLocationItems(profile);
      const contactPointItems = buildContactPoints(profile);
      const socialMediaItems = buildSocialMediaItems(profile);

      return (
        <Profile className="public">
          <Profile.Background image={backgroundImage} />
          <Profile.Image image={userImage} />
          <Profile.Section>
            <Profile.Name profile={profile} />
            {socialMediaItems.map((m) => (
              <SocialMediaLink key={m.id} type={m.type} value={m.value} />
            ))}
            <Profile.JobTitle profile={profile} />
            <Profile.Themes>
              {(profile.themes ?? []).map((x) => (
                <Profile.Theme key={x.value} themeId={x.value} />
              ))}
              <SubThemesSummary subThemes={profile.subThemes} />
            </Profile.Themes>
          </Profile.Section>
          <Profile.Section>
            <IconStack title="Location" items={locationItems} />
            <IconStack title="Information" items={contactPointItems} />
          </Profile.Section>
          <Profile.Section>
            <Profile.Biography profile={profile} />
          </Profile.Section>
        </Profile>
      );
    }}
  />
);

export default ViewParticipantProfile;
