import React from "react";
import { Label } from "semantic-ui-react";
import { formatEventLocation, getEventLocationUrl } from "modules/helpers";
import { ExternalLink } from "components/navigation";

const EventLocationLabel = ({
  eventFormat,
  locationType,
  location,
  noExternalLink,
  options,
  ...props
}) => {
  const locationUrl = getEventLocationUrl(location);
  const locationText = formatEventLocation(
    eventFormat,
    locationType,
    location,
    options
  );
  if (!locationText) return null;
  return (
    <Label
      {...props}
      basic
      icon="map marker alternate"
      content={
        locationUrl && !noExternalLink ? (
          <ExternalLink href={locationUrl}>{locationText}</ExternalLink>
        ) : (
          locationText
        )
      }
      size="large"
    />
  );
};

export default EventLocationLabel;
