import { Format } from "@redriver/cinnamon";
import { FieldDataList } from "components/containers";
import { RegistrationItemTypes } from "constants/enums";
import { DateTime } from "luxon";
import React from "react";
import { useSelector } from "react-redux";
import {
  getAllQuestions,
  getAllSectionsFormData,
  getParticipantIsVirtualAttendance,
  getTemplateVenueInfo,
} from "./selectors";
import TravelInfo from "./TravelInfo";

const RegistrationConclusion = ({ item }) => {
  const allQuestions = useSelector(getAllQuestions);
  const allStepsFormData = useSelector(getAllSectionsFormData);
  const venueInfo = useSelector(getTemplateVenueInfo);

  const itemsMissingPriorEventAnswer = allQuestions.filter(
    (x) =>
      !!x.mandatoryPriorEvent &&
      allStepsFormData[x.sectionId] &&
      !allStepsFormData[x.sectionId][x.id]
  );

  const accommodationRequiredQuestion = allQuestions.find(
    (x) => x.type == RegistrationItemTypes.AccommodationRequired
  );
  const requiresAccommodation = accommodationRequiredQuestion
    ? allStepsFormData[accommodationRequiredQuestion.sectionId][
        accommodationRequiredQuestion.id
      ]
    : false;

  const requiresTravelQuestions = allQuestions.filter(
    (x) =>
      x.type == RegistrationItemTypes.TravelBookingLocalTravelAssistance ||
      x.type == RegistrationItemTypes.TravelBookingFlightAssistance
  );
  const requiresTravel =
    requiresTravelQuestions.length > 0 &&
    requiresTravelQuestions.some((x) => allStepsFormData[x.sectionId][x.id]);

  const attendanceDatesQuestion = allQuestions.find(
    (x) => x.type == RegistrationItemTypes.AttendanceDates
  );
  const attendance = attendanceDatesQuestion
    ? allStepsFormData[attendanceDatesQuestion.sectionId][
        attendanceDatesQuestion.id
      ]
    : [];
  const isMultiDayAttendance =
    attendance &&
    attendance.arrivalDate &&
    attendance.departureDate &&
    DateTime.fromISO(attendance.arrivalDate) <
      DateTime.fromISO(attendance.departureDate);

  const isVirtualAttendance = useSelector(getParticipantIsVirtualAttendance);

  return (
    <div className="registration-conclusion">
      <p style={{ fontWeight: "bold" }}>
        Thank you for completing your registration for this event
      </p>
      <p>
        Your registration form will still be available up to the date of the
        event, if you need to make any changes.
      </p>
      {itemsMissingPriorEventAnswer.length > 0 && (
        <p>
          You will need to complete all the questions which are mandatory prior
          to an event.
        </p>
      )}

      {requiresTravel && <TravelInfo forceShow />}

      {venueInfo && !isVirtualAttendance && (
        <FieldDataList>
          {venueInfo.locationInformationParticipants && (
            <FieldDataList.Item title="About the venue">
              {venueInfo.locationInformationParticipants}
            </FieldDataList.Item>
          )}
          {venueInfo.arrivalTimeNotesParticipants && (
            <FieldDataList.Item title="Your Arrival">
              {venueInfo.arrivalTimeNotesParticipants}
            </FieldDataList.Item>
          )}
          {venueInfo.departureTimeNotesParticipants && (
            <FieldDataList.Item title="Your Departure">
              {venueInfo.departureTimeNotesParticipants}
            </FieldDataList.Item>
          )}
        </FieldDataList>
      )}
    </div>
  );
};

export default RegistrationConclusion;
