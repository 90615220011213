import React from "react";
import { Button } from "semantic-ui-react";
import { Wizard } from "@redriver/cinnamon";
import { useSelector } from "react-redux";
import { getSaving } from "./selectors";

const StepPrevious = ({}) => {
  const saving = useSelector(getSaving);
  return (
    <Wizard.Nav
      render={({
        steps,
        stepKey,
        activeStep,
        activeStepIndex,
        hasNextStep,
        hasPreviousStep,
        hasStep,
        onGoToNextStep,
        onGoToPreviousStep,
        onGoToStep,
        hidePrevious,
        hideNext,
      }) => {
        return hasPreviousStep ? (
          <Button disabled={saving} onClick={onGoToPreviousStep} primary>
            Previous
          </Button>
        ) : null;
      }}
    />
  );
};

export default StepPrevious;
