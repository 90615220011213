import React, { useState, useEffect } from "react";
import { PageLoader, useLookup, useResponsive } from "@redriver/cinnamon";
import { IconStack } from "components/icons";
import { SocialMediaLink } from "components/navigation";
import { Profile } from "components/profile";
import EditProfileDetails from "./EditProfileDetails";
import EditLocationDetails from "./EditLocationDetails";
import EditInformationDetails from "./EditInformationDetails";
import EditBiographyDetails from "./EditBiographyDetails";
import {
  buildSocialMediaItems,
  buildLocationItems,
  buildContactPoints,
} from "modules/helpers/profile";
import {
  currentUserProfileLookup,
  currentUserHasFutureEventProfilesLookup,
} from "../lookups";
import { ProfilePrivacyMessageBox } from "components/profile";
import { updateProfileDetails } from "./actions";
import { SubThemesSummary } from "components/themes";
import { MessageBox } from "components/containers";
import InfoIcon from "assets/icons/information.svg";

const EditProfile = () => {
  // pass profile into individual edit modals
  // Each modal onSubmitted will call setProfile with the updated data
  const [profile, setProfile] = useState({});
  const [profileUpdated, setProfileUpdated] = useState(false);

  const [{ loading, response }, resetLookup] = useLookup(
    currentUserProfileLookup
  );

  const [
    {
      loading: loadingHasFutureEvents,
      response: hasFutureEventProfilesResponse,
    },
    resetFutureEventProfilesLookup,
  ] = useLookup(currentUserHasFutureEventProfilesLookup);
  const hasFutureEventProfiles =
    !loadingHasFutureEvents && !!hasFutureEventProfilesResponse;

  const { screenSize } = useResponsive();
  const isMobile = screenSize === "mobile";

  useEffect(() => {
    if (response) setProfile(response);
  }, [response]);

  useEffect(() => {
    return () => profileUpdated && resetLookup();
  }, [profileUpdated]);

  if (loading || loadingHasFutureEvents) return <PageLoader />;

  const onProfileUpdated = (data) => {
    setProfile(data);
    setProfileUpdated(true);
  };

  const locationItems = buildLocationItems(profile);
  const contactPointItems = buildContactPoints(profile);
  const socialMediaItems = buildSocialMediaItems(profile);

  return (
    <Profile className="edit">
      <MessageBox title="Information" icon={InfoIcon}>
        <p>
          Your profile is the information that will be available to other
          participants at the events that you attend. You can update this
          information using the pencil edit buttons. <br />
          The visibility of the information within your profile can be set from
          the Privacy Settings option on the left.
        </p>
      </MessageBox>
      <Profile.Section style={{ paddingRight: 40 }}>
        <Profile.Name profile={profile} />
        {socialMediaItems.map((m) => (
          <SocialMediaLink
            key={m.id}
            type={m.type}
            value={m.value}
            disabled={!m.isPublic}
          />
        ))}
        <EditProfileDetails
          profile={profile}
          setProfile={onProfileUpdated}
          submitAction={updateProfileDetails}
          displayFutureProfileUpdateMessage={hasFutureEventProfiles}
          large
        />
        <Profile.JobTitle profile={profile} />
        <Profile.Themes>
          {(profile.themes ?? []).map((x) => (
            <Profile.Theme
              key={x.value}
              themeId={x.value}
              disabled={!profile?.isThemesPublic}
            />
          ))}
          <SubThemesSummary
            subThemes={profile.subThemes}
            disabled={!profile?.isThemesPublic}
          />
        </Profile.Themes>
      </Profile.Section>
      <Profile.Section>
        <IconStack
          title={
            <div>
              Location
              <EditLocationDetails
                profile={profile}
                setProfile={onProfileUpdated}
                displayFutureProfileUpdateMessage={hasFutureEventProfiles}
              />
            </div>
          }
          items={locationItems}
        />
        <IconStack
          title={
            <div>
              Contact Information
              <EditInformationDetails
                profile={profile}
                setProfile={onProfileUpdated}
                displayFutureProfileUpdateMessage={hasFutureEventProfiles}
              />
            </div>
          }
          items={contactPointItems}
        />
      </Profile.Section>
      <Profile.Section>
        <Profile.Biography profile={profile}>
          <EditBiographyDetails
            profile={profile}
            setProfile={onProfileUpdated}
            displayFutureProfileUpdateMessage={hasFutureEventProfiles}
          />
        </Profile.Biography>
      </Profile.Section>
    </Profile>
  );
};

export default EditProfile;
