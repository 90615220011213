import React from "react";
import classNames from "classnames";
import ProgrammeDate from "./ProgrammeDate";
import ProgrammeActivity from "./ProgrammeActivity";
import ProgrammeInfo from "./ProgrammeInfo";
import * as content from "./content";

const Programme = ({ className, ...props }) => (
  <div {...props} className={classNames("programme", className)} />
);

Programme.Date = ProgrammeDate;
Programme.Activity = ProgrammeActivity;
Programme.Info = ProgrammeInfo;

Programme.ProgrammeItem = content.ProgrammeItem;
Programme.LiveSession = content.LiveSession;

export default Programme;
