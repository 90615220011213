import { registerLookup } from "@redriver/cinnamon";
import { getProfile, hasFutureEventProfiles } from "./actions";

export const currentUserProfileLookup = registerLookup(
  "currentUserProfileLookup",
  getProfile
);

export const currentUserHasFutureEventProfilesLookup = registerLookup(
  "currentUserHasFutureEventProfiles",
  hasFutureEventProfiles
);
