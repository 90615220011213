import React from "react";
import { Popup } from "semantic-ui-react";
import { InfoIcon } from "components/icons";

const RegistrationLabel = ({
  label,
  subHeader,
  infoText,
  labelPopupContent,
  extraLabelContent,
}) => {
  const labelContent = labelPopupContent ? (
    <Popup
      inverted
      trigger={<span>{label}</span>}
      position="top center"
      wide
      content={labelPopupContent}
    />
  ) : (
    <span>{label}</span>
  );

  return (
    <React.Fragment>
      {label && (
        <label>
          {labelContent}
          {infoText && (
            <Popup
              inverted
              position="top center"
              content={infoText}
              trigger={
                <InfoIcon style={{ marginLeft: "0.25em", marginRight: 0 }} />
              }
            />
          )}
        </label>
      )}
      {subHeader && <span className="sub-header">{subHeader}</span>}
      {extraLabelContent}
    </React.Fragment>
  );
};
export default RegistrationLabel;
