import React from "react";
import { Format } from "@redriver/cinnamon";

const getPageMin = (pageSize, pageNumber) =>
  pageSize * pageNumber - (pageSize - 1);

const getPageMax = (pageSize, pageNumber, total) => {
  const pageTotal = pageSize * pageNumber;
  return total < pageTotal ? total : pageTotal;
};

const ResultsCounter = ({ pageSize, pageNumber, totalItems }) => (
  <label className="results-counter">
    {!!totalItems && (
      <React.Fragment>
        <Format.Number value={getPageMin(pageSize, pageNumber)} /> -{" "}
        <Format.Number value={getPageMax(pageSize, pageNumber, totalItems)} />{" "}
        of <Format.Number value={totalItems} /> result
        {totalItems == 1 ? "" : "s"}
      </React.Fragment>
    )}
  </label>
);

export default ResultsCounter;
