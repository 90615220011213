import React from "react";
import { Label } from "semantic-ui-react";
import { formatEventDates } from "modules/helpers";

const EventDatesLabel = ({ startDate, endDate, options, ...props }) => {
  const dateText = formatEventDates(startDate, endDate, options);
  if (!dateText) return null;
  return (
    <Label
      basic
      icon="calendar outline"
      content={dateText}
      size="large"
      {...props}
    />
  );
};

export default EventDatesLabel;
