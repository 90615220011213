import React from "react";
import { useLookup } from "@redriver/cinnamon";
import { Label } from "semantic-ui-react";
import { eventCategoriesLookup } from "modules/lookups";

const EventCategoryLabel = ({ category, ...props }) => {
  const [lookupData] = useLookup(eventCategoriesLookup);
  const text = (lookupData?.response || []).find((x) => x.value == category)
    ?.text;
  if (!text) return null;
  return <Label {...props} basic icon="tag" content={text} size="large" />;
};

export default EventCategoryLabel;
