import React from "react";
import styled, { css } from "styled-components";
import { Tile } from "@redriver/cinnamon";
import PropTypes from "prop-types";

// Getting colour percentages
/*  colour array = [c0, c1, c2, c3..., cn]
 *   p = 100 /Number of colours,
 *   linear-gradient = c0 p%, c1 p% (2*p)%, c2 (2*p)% (3*p)%, ... cn (n*p)% ((n + 1)*p)%
 */
const CalculateLinearGradient = (colours) => {
  if (!colours || !colours.length) return "";

  const total = colours.length;
  const percentage = 100 / total;

  const percentageArray = colours.map(
    (c, i) => `${c} ${i * percentage}% ${(i + 1) * percentage}%`
  );

  return percentageArray.join();
};

const TileWrapper = styled(Tile)`
  ${(props) =>
    css`
      border-image: linear-gradient(
          to bottom,
          ${CalculateLinearGradient(props.colours)}
        )
        5 !important;
    `};
`;

const ColouredTile = ({ children, ...props }) => {
  return <TileWrapper {...props}>{children}</TileWrapper>;
};

ColouredTile.propTypes = {
  colours: PropTypes.arrayOf(PropTypes.string),
};

export default ColouredTile;
