import React from "react";
import { Label, Popup } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";

const EventLastViewedLabel = ({ lastViewed, ...props }) => (
  <Popup
    inverted
    content="Last viewed"
    trigger={
      <Label
        {...props}
        basic
        icon="eye"
        size="large"
        content={
          <Format.DateTime
            value={lastViewed}
            format={({ isToday, isYesterday }) => {
              if (isToday) return "[Today at] h:mma";
              if (isYesterday) return "[Yesterday at] h:mma";
              return "h:mma Do MMM YYYY";
            }}
          />
        }
      />
    }
  />
);

export default EventLastViewedLabel;
