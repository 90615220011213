import React, { useState } from "react";
import { ModalBuilder, Form, Modal } from "@redriver/cinnamon";
import { updateProfileDetails } from "./actions";
import { FloatingEditButton } from "components/buttons";
import produce from "immer";
import { Icon, Popup } from "semantic-ui-react";
import { InfoIcon } from "components/icons";
import ShouldUpdateFutureEventProfilesMessage from "./ShouldUpdateFutureEventProfilesMessage";

const EditBiographyDetails = ({
  profile,
  setProfile,
  displayFutureProfileUpdateMessage,
  ...props
}) => {
  const [formData, setFormData] = useState(false);
  const initialData = {
    biography: {
      bio: profile?.bio,
    },
  };

  return (
    <ModalBuilder
      withForm
      initialData={initialData}
      renderTrigger={(openModal) => (
        <FloatingEditButton
          onClick={() => {
            openModal();
            setFormData(initialData);
          }}
          {...props}
        />
      )}
      submitAction={updateProfileDetails}
      onSubmitted={() => {
        const nextProfile = produce(profile, (draft) => {
          draft.bio = formData.biography.bio;
        });
        setProfile(nextProfile);
      }}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Edit Profile Biography">
          <Form
            {...formProps}
            onChange={(field, data, applyChanges) => {
              formProps.onChange(field, data, applyChanges);
              setFormData(applyChanges(formProps.value));
            }}
          >
            <Form.Object field="biography">
              <Form.TextArea
                field="bio"
                label={
                  <label>
                    Biography
                    <Popup
                      inverted
                      content="Please provide a short biography (bio), no more than 200 words, for inclusion in our participant literature. If your bio is included it will not be edited and will appear as you have supplied it."
                      trigger={<InfoIcon />}
                    />
                  </label>
                }
                fluid
                placeholder="Enter a short biography to display on your profile"
                style={{ height: 120 }}
              />
            </Form.Object>
            {displayFutureProfileUpdateMessage && (
              <ShouldUpdateFutureEventProfilesMessage />
            )}
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditBiographyDetails;
