import React from "react";
import { useResponsive } from "@redriver/cinnamon";
import { EditPrivacySettings } from "features/Profile/EditPrivacySettings";
import { ConfirmPrivacySettings } from "features/Profile/ConfirmPrivacySettings";

const PrivacySettingsPage = () => {
  const { screenSize } = useResponsive();
  const isMobile = screenSize === "mobile";
  return (
    <React.Fragment>
      <EditPrivacySettings />
      {isMobile && (
        <div style={{ margin: "1em" }}>
          <ConfirmPrivacySettings />
        </div>
      )}
    </React.Fragment>
  );
};

export default PrivacySettingsPage;
