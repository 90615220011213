import React from "react";
import { Header } from "semantic-ui-react";
import classNames from "classnames";

const ProfileName = ({ profile, disabled, children, className, ...props }) => {
  const css = classNames("profile-name", { disabled }, className);
  return (
    <section className={css} {...props}>
      <Header as="h3" className="display-name">
        {profile?.displayName}
      </Header>
      {children}
    </section>
  );
};

export default ProfileName;
