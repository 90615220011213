import React from "react";
import { Button } from "semantic-ui-react";
import { Wizard, lookupReset } from "@redriver/cinnamon";
import { saveSection } from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { getSaving } from "./selectors";
import { currentUserProfileLookup } from "features/Profile/lookups";

const StepNext = ({
  onFinish,
  eventId,
  participantId,
  formIsValid,
  formData,
  nextLabel = "Save & Next",
  className,
  submitForm,
  saveSectionAction,
}) => {
  const dispatch = useDispatch();
  const saving = useSelector(getSaving);
  return (
    <Wizard.Nav
      render={({
        steps,
        stepKey,
        activeStep,
        activeStepIndex,
        hasNextStep,
        hasPreviousStep,
        hasStep,
        onGoToNextStep,
        onGoToPreviousStep,
        onGoToStep,
        hidePrevious,
        hideNext,
      }) => {
        const save = (callback) => {
          if (saveSectionAction) {
            if (formIsValid) {
              dispatch(
                saveSection(formData, {
                  eventId,
                  sectionId: activeStep.key,
                  participantId,
                  canProgress: true,
                  saveSectionAction,
                })
              ).then(() => {
                dispatch(lookupReset(currentUserProfileLookup));
                if (callback) {
                  callback();
                }
              });
            } else if (!hasNextStep && callback) {
              callback();
            } else {
              submitForm();
            }
          } else {
            if (callback) {
              callback();
            }
          }
        };
        return (
          <Button
            className={className}
            loading={saving}
            disabled={saving}
            onClick={() => save(hasNextStep ? onGoToNextStep : onFinish)}
            primary
          >
            {nextLabel}
          </Button>
        );
      }}
    />
  );
};

export default StepNext;
