import React from "react";

const ExtraRegistrationItemContent = ({ item, ...props }) => (
  <div {...props}>
    <div>
      Is {item?.mandatoryPriorEvent ? "mandatory" : "optional"} prior to event.
    </div>
    {item?.isCustomItem && (
      <React.Fragment>
        <div>
          Is {item?.onInternalList ? "displayed" : "hidden"} on internal
          participants list.
        </div>
        <div>
          Is {item?.onExternalList ? "displayed" : "hidden"} on external
          participants list.
        </div>
        <div>
          {item?.cleanse
            ? `Data will be cleansed after ${item?.cleanseAfterWeeks} week(s).`
            : "Data will not be cleansed."}
        </div>
      </React.Fragment>
    )}
  </div>
);

export default ExtraRegistrationItemContent;
