import React from "react";
import classNames from "classnames";
import ProfileImage from "./ProfileImage";
import ProfileBackground from "./ProfileBackground";
import ProfileSection from "./ProfileSection";
import ProfileName from "./ProfileName";
import ProfileJobTitle from "./ProfileJobTitle";
import ProfileThemes from "./ProfileThemes";
import ProfileTheme from "./ProfileTheme";
import ProfileBiography from "./ProfileBiography";

const Profile = ({ className, ...props }) => (
  <div {...props} className={classNames("profile", className)} />
);

Profile.Image = ProfileImage;
Profile.Background = ProfileBackground;
Profile.Section = ProfileSection;
Profile.Name = ProfileName;
Profile.JobTitle = ProfileJobTitle;
Profile.Themes = ProfileThemes;
Profile.Theme = ProfileTheme;
Profile.Biography = ProfileBiography;

export default Profile;
