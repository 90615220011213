import React from "react";
import classNames from "classnames";
import { Icon } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";

const DateDisplayLabel = ({
  as: As = "div",
  className,
  name,
  date,
  icon,
  iconImage,
  ...props
}) => {
  return (
    <As className={classNames("date-display", className)} {...props}>
      {icon ? <Icon name={icon} /> : iconImage ? <img src={iconImage} /> : null}
      {name && <span className="name">{name}</span>}
      <Format.Date value={date} format="DD MMM YYYY" />
    </As>
  );
};

export default DateDisplayLabel;
