import { apiPut } from "@redriver/cinnamon";
import { produce } from "immer";

const NAMESPACE = "PROFILE/EDIT_PROFILE";

const ActionTypes = {
  UpdateProfileDetails: `${NAMESPACE}/UPDATE_PROFILE_DETAILS`,
};

export const updateProfileDetails = (data) => {
  const request = produce(data, (draft) => {
    if (draft.information && Array.isArray(draft.information.contactPoints)) {
      draft.information.contactPoints.forEach((p) => {
        if (typeof p.id != "string") {
          p.id = null;
        }
      });
    }
    if (draft.details && Array.isArray(draft.details.otherSocialMedia)) {
      draft.details.otherSocialMedia.forEach((m) => {
        if (typeof m.id != "string") {
          m.id = null;
        }
        m.socialMediaTypeId = m.type;
        delete m.typeOption;
        delete m.type;
      });
    }

    if (
      draft.details &&
      draft.details.organisationId &&
      draft.details.organisationId == draft.details.organisationName
    ) {
      delete draft.details.organisationId;
    }

    if (
      draft.details &&
      Array.isArray(draft.details.subThemes) &&
      Array.isArray(draft.details.subThemeNames)
    ) {
      let aux = draft.details.subThemeNames;
      draft.details.subThemeNames = [];
      data.details.subThemes.forEach((s) => {
        if (aux.includes(s)) {
          draft.details.subThemeNames.push(s);
          let index = draft.details.subThemes.indexOf(s);
          draft.details.subThemes.splice(index, 1);
        }
      });
    }
  });

  return apiPut(ActionTypes.UpdateProfileDetails, `profile`, request);
};
