import React from "react";
import { Profile, AddProfileImage } from "components/profile";
import { deleteProfileImage, updateProfileImage } from "./actions";
import { ProfileImageType } from "constants/enums";
import { myProfileImagesLookup } from "modules/lookups";
import { Lookup } from "@redriver/cinnamon";
import { FloatingEditButton } from "components/buttons";
import { ProfileHeaderImage, ProfileUserImage } from "constants/images";

const EditProfileHeader = () => (
  <Lookup
    lookup={myProfileImagesLookup}
    render={({ loading, response }, resetLookup) => {
      const profileImages = response || [];
      const userImage = profileImages.find(
        (x) => x.type == ProfileImageType.User
      )?.originalUrl;
      const backgroundImage = profileImages.find(
        (x) => x.type == ProfileImageType.Background
      )?.originalUrl;
      return (
        <Profile className="header">
          <Profile.Background image={backgroundImage}>
            <AddProfileImage
              as={FloatingEditButton}
              large
              className="profile-header-edit-link"
              updateAction={updateProfileImage}
              deleteAction={deleteProfileImage}
              profileImageType={ProfileImageType.Background}
              onUpdated={resetLookup}
              minHeight={ProfileHeaderImage.minHeight}
              minWidth={ProfileHeaderImage.minWidth}
              maxAspect={ProfileHeaderImage.aspect}
              minAspect={ProfileHeaderImage.aspect}
            />
          </Profile.Background>
          <AddProfileImage
            as={Profile.Image}
            className="edit-profile-image"
            updateAction={updateProfileImage}
            deleteAction={deleteProfileImage}
            profileImageType={ProfileImageType.User}
            onUpdated={resetLookup}
            minHeight={ProfileUserImage.minHeight}
            minWidth={ProfileUserImage.minWidth}
            maxAspect={ProfileUserImage.aspect}
            minAspect={ProfileUserImage.aspect}
            image={userImage}
          />
        </Profile>
      );
    }}
  />
);

export default EditProfileHeader;
