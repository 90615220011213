import React from "react";
import classNames from "classnames";
import { Header, Icon } from "semantic-ui-react";
import { Tile } from "@redriver/cinnamon";
import { formatResourceInfo } from "modules/helpers";
import { ResourceLink } from "../navigation";
import { ResourceIcon } from "../icons";
import ResourceTileAction from "./ResourceTileAction";

const ResourceTile = ({ resource, status, className, children }) => {
  const info = formatResourceInfo(
    resource.type,
    resource.fileName,
    resource.fileSize,
    resource.url
  );

  return (
    <Tile className={classNames("resource-tile", className)}>
      <div className="details">
        <Header as="h5">
          {resource.name}
          <Header.Subheader>
            {info && <span className="info">{info}</span>}
            {status && <span className="status">{status}</span>}
          </Header.Subheader>
        </Header>
        <div className="actions">{children}</div>
        <div className="description">{resource.description}</div>
      </div>
      <ResourceLink resource={resource}>
        <ResourceIcon resource={resource} />
      </ResourceLink>
    </Tile>
  );
};

ResourceTile.Action = ResourceTileAction;

export default ResourceTile;
