import { ContactPointIcon, SocialMediaLinkType } from "constants/enums";
import FlagIcon from "assets/icons/flag.svg";
import LocationIcon from "assets/icons/location.svg";
import WorldIcon from "assets/icons/world.svg";
import { getContactPointIcon } from "modules/helpers/icons";
import { produce } from "immer";

export function buildSocialMediaItems(profile) {
  const socialMediaItems = (profile?.otherSocialMedia || []).map((x) => ({
    type: x.type,
    value: x.value,
    id: x.id,
    isPublic: x.isPublic,
  }));
  if (profile?.twitter) {
    socialMediaItems.unshift({
      type: SocialMediaLinkType.Twitter,
      value: profile.twitter,
      id: "main-twitter",
      isPublic: profile.isTwitterPublic,
    });
  }
  if (profile?.linkedIn) {
    socialMediaItems.unshift({
      type: SocialMediaLinkType.LinkedIn,
      value: profile.linkedIn,
      id: "main-linkedin",
      isPublic: profile.isLinkedInPublic,
    });
  }
  return socialMediaItems;
}

export function buildLocationItems(profile, splitCitizenships = false) {
  let items = [
    {
      icon: LocationIcon,
      text: profile?.town ?? "No town entered",
      disabled: false,
      id: "town",
      tooltip: "Address Town/City",
    },
    {
      icon: WorldIcon,
      text: profile?.countryId
        ? profile?.countryDescription
        : "No country entered",
      disabled: false,
      id: "country",
      tooltip: "Address Country",
    },
  ];

  if (splitCitizenships) {
    const citizenships = [
      {
        icon: FlagIcon,
        text: profile?.citizenship ?? "No citizenship entered",
        disabled: false,
        id: "citizenship",
        tooltip: "Citizenship Country",
      },
      {
        icon: FlagIcon,
        text: profile?.secondaryCitizenship,
        disabled: false,
        id: "secondaryCitizenship",
        tooltip: "Secondary Citizenship Country",
      },
    ];
    items = [...items, ...citizenships];
  } else {
    items.push({
      icon: FlagIcon,
      text:
        profile &&
        (profile?.citizenship ?? "No citizenship entered") +
          (profile?.citizenship
            ? profile?.secondaryCitizenship
              ? (profile?.citizenship ? ", " : "") +
                profile?.secondaryCitizenship
              : ""
            : ""),
      disabled: false,
      id: "citizenShip",
      tooltip: "Citizenship",
    });
  }

  return items.filter((x) => !!x.text);
}

export function buildContactPoints(profile) {
  const contactPointItems = (profile?.contactPoints || []).map((x) => ({
    icon: getContactPointIcon(x.type),
    text: x.value,
    id: x.id,
    isPublic: x.isPublic,
    disabled: !x.isPublic,
    tooltip: x.type,
  }));

  if (profile?.phone) {
    contactPointItems.unshift({
      icon: getContactPointIcon(ContactPointIcon.Phone),
      text: profile?.phone,
      disabled: profile && !profile?.isTelephonePublic,
      id: "main-phone",
      isPublic: profile?.isTelephonePublic,
      tooltip: ContactPointIcon.Phone,
    });
  }

  if (profile?.mobile) {
    contactPointItems.unshift({
      icon: getContactPointIcon(ContactPointIcon.Mobile),
      text: profile?.mobile,
      disabled: profile && !profile?.isMobilePhonePublic,
      id: "main-mobile",
      isPublic: profile?.isMobilePhonePublic,
      tooltip: ContactPointIcon.Mobile,
    });
  }

  if (profile?.email) {
    contactPointItems.push({
      icon: getContactPointIcon(ContactPointIcon.Email),
      text: profile.email,
      disabled: profile && !profile?.isEmailPublic,
      id: "main-email",
      isPublic: profile?.isEmailPublic,
      tooltip: ContactPointIcon.Email,
    });
  }
  return contactPointItems;
}

export function calcSocialMediaItemChanges(socialMediaItem, profile, checked) {
  const changeset = {};
  if (socialMediaItem.id == "main-twitter") {
    changeset.isTwitterPublic = checked;
  } else if (socialMediaItem.id == "main-linkedin") {
    changeset.isLinkedInPublic = checked;
  } else {
    const item = profile.otherSocialMedia.find(
      (a) => a.id == socialMediaItem.id
    );
    if (item) {
      changeset.otherSocialMedia = [{ ...item, isPublic: checked }];
    }
  }
  const next = produce(profile, (draft) => {
    if (changeset.isTwitterPublic != undefined) {
      draft.isTwitterPublic = changeset.isTwitterPublic;
    }
    if (changeset.isLinkedInPublic != undefined) {
      draft.isLinkedInPublic = changeset.isLinkedInPublic;
    }
    if (
      changeset.otherSocialMedia != undefined &&
      changeset.otherSocialMedia.length > 0
    ) {
      const item = draft.otherSocialMedia.find(
        (a) => a.id == changeset.otherSocialMedia[0].id
      );
      if (item) {
        item.isPublic = changeset.otherSocialMedia[0].isPublic;
      }
    }
  });
  return {
    changeset,
    nextProfile: next,
  };
}

export function calcContactPointChanges(contactPoint, profile, checked) {
  const changeset = {};
  if (contactPoint.id == "main-phone") {
    changeset.isTelephonePublic = checked;
  } else if (contactPoint.id == "main-mobile") {
    changeset.isMobilePhonePublic = checked;
  } else if (contactPoint.id == "main-email") {
    changeset.isEmailPublic = checked;
  } else {
    const item = profile.contactPoints.find((a) => a.id == contactPoint.id);
    if (item) {
      changeset.contactPoints = [{ ...item, isPublic: checked }];
    }
  }

  const next = produce(profile, (draft) => {
    if (changeset.isTelephonePublic != undefined) {
      draft.isTelephonePublic = changeset.isTelephonePublic;
    }
    if (changeset.isMobilePhonePublic != undefined) {
      draft.isMobilePhonePublic = changeset.isMobilePhonePublic;
    }
    if (changeset.isEmailPublic != undefined) {
      draft.isEmailPublic = changeset.isEmailPublic;
    }
    if (
      changeset.contactPoints != undefined &&
      changeset.contactPoints.length > 0
    ) {
      const item = draft.contactPoints.find(
        (a) => a.id == changeset.contactPoints[0].id
      );
      if (item) {
        item.isPublic = changeset.contactPoints[0].isPublic;
      }
    }
  });
  return {
    changeset,
    nextProfile: next,
  };
}
