import { apiGet, apiRequests } from "@redriver/cinnamon";

const NAMESPACE = "PROFILE/VIEW_PUBLIC_PROFILE";

const ActionTypes = {
  GetProfileDetails: `${NAMESPACE}/GET_PROFILE_DETAILS`,
  GetProfileImages: `${NAMESPACE}/GET_PROFILE_IMAGES`,
};

const getProfileDetails = (userId) =>
  apiGet(ActionTypes.GetProfileDetails, `profile/public/${userId}`);

const getProfileImages = (userId) =>
  apiGet(ActionTypes.GetProfileImages, `profile/public/${userId}/images`);

export const getPublicProfile = (userId) =>
  apiRequests({
    images: getProfileImages(userId),
    profile: getProfileDetails(userId),
  });
