import React from "react";
import { Form, ModalBuilder } from "@redriver/cinnamon";
import { YesNoRadioGroup } from "components/forms";
import { FloatingEditButton } from "components/buttons";
import { FormModal } from "components/modals";
import {
  registrationItemTypesLookup,
  registrationItemWidthsLookup,
} from "./lookups";
import { addItem } from "./actions";

const AddRegistrationItemModal = ({
  item,
  as: As = FloatingEditButton,
  eventId,
  groupId,
  onSubmitted,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={addItem}
      submitParams={{ eventId, groupId }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
      renderModal={(modalProps, formProps) => (
        <FormModal
          {...modalProps}
          header="Add Registration Question"
          size="small"
        >
          <Form {...formProps} className="edit-registration-item">
            <Form.Input field="label" label="Question text" fluid />
            <Form.Input field="subHeader" label="Question sub heading" fluid />
            <Form.Input
              field="info"
              label="Text to appear in the info icon for this question"
              fluid
            />
            <Form.Group widths={2}>
              <Form.Dropdown
                field="type"
                label="What type of answer does this question require?"
                fluid
                required
                lookup={registrationItemTypesLookup}
              />
              <Form.Dropdown
                field="width"
                label="What width within a row does this question take up?"
                fluid
                required
                lookup={registrationItemWidthsLookup}
              />
            </Form.Group>
            <Form.Group widths={2}>
              <YesNoRadioGroup
                field="required"
                label="Is this mandatory to submit the form?"
              />
              <YesNoRadioGroup
                field="mandatoryPriorEvent"
                label="Is this mandatory prior to the event?"
              />
            </Form.Group>
            {/* <Form.Group widths={2}>
              <YesNoRadioGroup
                field="onInternalList"
                label="Show on internal participants list?"
              />
              <YesNoRadioGroup
                field="onExternalList"
                label="Show on external participants list?"
              />
            </Form.Group> */}
            <Form.Group widths={2}>
              <YesNoRadioGroup field="cleanse" label="Cleanse data?" />
            </Form.Group>
            <Form.Group widths={2}>
              <Form.If condition={({ fields }) => fields.cleanse}>
                <Form.Field className="weeks-selector">
                  <Form.Numeric
                    required
                    field="cleanseAfterWeeks"
                    label="Cleanse data after"
                    minValue={1}
                    decimalPlaces={0}
                    subLabel="Week(s)"
                    subLabelPosition="right"
                    fluid
                  />
                </Form.Field>
              </Form.If>
            </Form.Group>
          </Form>
        </FormModal>
      )}
    />
  );
};

export default AddRegistrationItemModal;
