import React from "react";
import { Header } from "semantic-ui-react";
import classNames from "classnames";

const ProfileBiography = ({ profile, className, children, ...props }) => {
  const css = classNames(
    "profile-biography",
    { disabled: !profile?.isBiographyPublic && !!profile?.bio },
    className
  );
  return (
    <section className={css} {...props}>
      <Header as="h4">
        <span>Biography</span>
        {children}
      </Header>
      <div className="bio-text">{profile?.bio || "-"}</div>
    </section>
  );
};

export default ProfileBiography;
