import { RegistrationItemWidths } from "constants/enums";

export const getColsWidth = (widthType, minColSize) => {
  let colSize;
  switch (widthType) {
    case RegistrationItemWidths.Thirds:
      colSize = 2;
      break;
    case RegistrationItemWidths.Half:
      colSize = 3;
      break;
    default:
      colSize = 6;
      break;
  }

  if (minColSize && colSize < minColSize) {
    colSize = minColSize;
  }

  return colSize;
};
