import React from "react";
import { Lookup } from "@redriver/cinnamon";
import { participantEventDetailsLookup } from "modules/lookups";

const RegistrationEventDetails = ({ summary, eventId }) => (
  <Lookup
    lookup={participantEventDetailsLookup}
    lookupParams={eventId}
    render={({ loading, response }, resetLookup) => {
      return response ? (
        <div className="registration-welcome">
          <p>
            Welcome to the registration{" "}
            {response ? (
              <React.Fragment>
                for <span className="name">{response.name}</span>
              </React.Fragment>
            ) : (
              ""
            )}
            . Please follow the steps and complete all information as accurately
            as possible. Don't worry if you do not have the information to hand,
            this can be updated at a later date.
          </p>
          {summary && (
            <React.Fragment>
              <p>Below you can read the summary of the event.</p>
              <div>{summary}</div>
            </React.Fragment>
          )}
        </div>
      ) : null;
    }}
  />
);

export default RegistrationEventDetails;
