import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { ContentWithMenu, ProfileMenuItems } from "components/menus";
import { AppPageContent } from "components/pages";
import { AppRoutes, AccountRoutes } from "constants/routes";
import { EditProfileHeader } from "features/Profile";
import MyAccountPage from "./MyAccountPage";
import MyProfilePage from "./MyProfilePage";
import PrivacySettingsPage from "./PrivacySettingsPage";
import { ConfirmPrivacySettings } from "features/Profile/ConfirmPrivacySettings";
import { ProfilePrivacyMessageBox } from "components/profile";

const AccountRouter = () => (
  <AppPageContent wide>
    <Segment style={{ padding: 0 }}>
      <EditProfileHeader />
      <ContentWithMenu
        menuItems={<ProfileMenuItems />}
        className="profile-content"
        columnFooter={
          <Switch>
            <Route
              path={AccountRoutes.Privacy}
              component={ConfirmPrivacySettings}
            />
          </Switch>
        }
      >
        <Switch>
          <Route exact path={AppRoutes.Account} component={MyAccountPage} />
          <Route path={AccountRoutes.Profile} component={MyProfilePage} />
          <Route path={AccountRoutes.Privacy} component={PrivacySettingsPage} />
          <Route render={() => <Redirect to={AppRoutes.Account} />} />
        </Switch>
      </ContentWithMenu>
    </Segment>
  </AppPageContent>
);

export default AccountRouter;
