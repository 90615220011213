import React from "react";
import classNames from "classnames";
import { ThemeLabel } from "components/labels";

const ProfileTheme = ({ themeId, disabled }) => (
  <ThemeLabel
    themeId={themeId}
    className={classNames("profile-theme", disabled ? "disabled" : null)}
  />
);

export default ProfileTheme;
