import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Header, Icon } from "semantic-ui-react";
import { Content } from "@redriver/cinnamon";
import { ExternalLink } from "components/navigation";
import { copyToClipboard } from "modules/helpers";

const LiveSession = ({
  liveSession,
  draft,
  placeholder,
  className,
  children,
  onClick,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    const timeout = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <div
      {...props}
      className={classNames(
        "live-session",
        {
          draft,
          placeholder: placeholder && !children,
          upcoming: liveSession?.upcoming,
          selectable: onClick,
        },
        className
      )}
      onClick={onClick}
    >
      {children ||
        (!placeholder && liveSession ? (
          <React.Fragment>
            <Header as="h5">
              {liveSession.title}
              {liveSession.url && (
                <React.Fragment>
                  <Header.Subheader>
                    {" - "}
                    <ExternalLink
                      href={liveSession.url}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Click here to join the online meeting
                    </ExternalLink>
                  </Header.Subheader>
                  <Header.Subheader>
                    {" or "}
                    <a
                      onClick={async (e) => {
                        e.stopPropagation();
                        const success = await copyToClipboard(liveSession.url);
                        if (success) setCopied(true);
                      }}
                    >
                      Click here to copy the link
                    </a>
                    <Content visible={copied} marginLeft="medium">
                      Copied <Icon name="check" color="green" />
                    </Content>
                  </Header.Subheader>
                </React.Fragment>
              )}
            </Header>
            {liveSession.description && <p>{liveSession.description}</p>}
            {liveSession.joiningInfo && (
              <p>
                <a
                  onClick={(e) => {
                    setExpanded(!expanded);
                    e.stopPropagation();
                  }}
                >
                  {expanded ? "Hide" : "Show"} Joining Information
                </a>
              </p>
            )}
            {liveSession.joiningInfo && expanded && (
              <p style={{ whiteSpace: "pre-wrap" }}>
                {liveSession.joiningInfo}
              </p>
            )}
          </React.Fragment>
        ) : (
          placeholder
        ))}
    </div>
  );
};

export default LiveSession;
