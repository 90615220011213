import React from "react";
import classNames from "classnames";
import { Segment } from "semantic-ui-react";
import { SidePanel } from "@redriver/cinnamon";
import { ScrollableContent } from "components/containers";
import { UserIcon } from "components/icons";
import { ViewParticipantProfile } from "features/Profile";
import { canCloseSidePanel } from "modules/helpers";

const AttendingParticipant = ({ participant, children }) => {
  const {
    id,
    userId,
    displayName,
    jobTitle,
    biography,
    thumbnailUrl,
    organisationName,
    representingText,
    townOrCity,
    country,
    attendanceCategoryDescription,
  } = participant;

  const organisation = [organisationName, townOrCity, country]
    .filter((x) => !!x)
    .join(", ");

  const content = (
    <Segment
      className={classNames("attending-participant", { selectable: !!id })}
    >
      <UserIcon image={thumbnailUrl} />
      <div className="name">{displayName ?? "Participant"}</div>
      <div className="attending-info">
        {representingText && (
          <div className="representing">{representingText}</div>
        )}
        <div className="job">
          {jobTitle && (
            <span className="title">
              {jobTitle}
              {organisation ? "," : ""}&nbsp;
            </span>
          )}
          {organisation && <span className="org">{organisation}</span>}
        </div>
      </div>
      <div className="attendance-category">{attendanceCategoryDescription}</div>
    </Segment>
  );

  if (!id) return content;

  return (
    <SidePanel
      trigger={content}
      closeIcon
      size="60em"
      className="attending-participant-profile"
      onClosing={canCloseSidePanel}
    >
      <ScrollableContent>{children}</ScrollableContent>
    </SidePanel>
  );
};

export default AttendingParticipant;
