import React from "react";
import { Form, useFormField, useFormState } from "@redriver/cinnamon";
import { RegistrationItemTypes, RegistrationItemWidths } from "constants/enums";
import { ColumnGrid } from "components/containers";
import { UserTitleDropdown } from "components/forms";
import RegistrationLabel from "./RegistrationLabel";
import { useSelector } from "react-redux";
import { getAllQuestions } from "./selectors";
import { getColsWidth } from "./helpers";

const AssistantDetailsItem = ({ item, children, isInEditMode, minColSize }) => {
  const { fields } = useFormState();
  const questions = useSelector(getAllQuestions);
  const relatedQuestionIds = questions.filter(
    (x) => x.type == RegistrationItemTypes.LiaiseWithAssistant
  );

  const hasAgreed =
    isInEditMode || relatedQuestionIds.some((x) => fields[x.id] == true);

  const itemColWidth = getColsWidth(RegistrationItemWidths.Thirds, minColSize);
  const fullColWidth = getColsWidth(RegistrationItemWidths.Full, minColSize);

  const [contactItem] = useFormField(item.id);

  const detailsRequired =
    !!item.required ||
    (!!contactItem && Object.keys(contactItem).some((k) => contactItem[k]));

  if (!hasAgreed) {
    return null;
  }

  return (
    <Form.Object field={item.id} propagateUpdates="always">
      <div style={{ display: "flex" }}>
        <Form.Field
          required={item.required}
          label={
            <RegistrationLabel
              label={item.label}
              subHeader={item.subHeading}
              infoText={item.infoText}
            />
          }
          style={{ marginBottom: 0 }}
        ></Form.Field>
        {children}
      </div>

      <ColumnGrid cols={6}>
        <ColumnGrid.Item colsWidth={itemColWidth}>
          <UserTitleDropdown field="title" label="Title" fluid />
        </ColumnGrid.Item>
        <ColumnGrid.Item colsWidth={itemColWidth}>
          <Form.Input
            field="firstName"
            label="First Name"
            fluid
            required={detailsRequired}
          />
        </ColumnGrid.Item>
        <ColumnGrid.Item colsWidth={itemColWidth}>
          <Form.Input
            field="lastName"
            label="Family Name"
            fluid
            required={detailsRequired}
          />
        </ColumnGrid.Item>
        <ColumnGrid.Item colsWidth={itemColWidth}>
          <Form.Input field="phone" label="Phone" fluid />
        </ColumnGrid.Item>
        <ColumnGrid.Item colsWidth={itemColWidth}>
          <Form.Email
            field="email"
            label="Email"
            fluid
            required={detailsRequired}
          />
        </ColumnGrid.Item>
        <ColumnGrid.Item colsWidth={fullColWidth}></ColumnGrid.Item>
      </ColumnGrid>
    </Form.Object>
  );
};

export default AssistantDetailsItem;
