import React from "react";
import classNames from "classnames";
import { Label } from "semantic-ui-react";

const DarkDisplayLabel = ({ children, secondary, className, ...props }) => (
  <Label
    className={classNames(
      "dark-display-label",
      { secondary: secondary },
      className
    )}
    {...props}
  >
    {children}
  </Label>
);

export default DarkDisplayLabel;
