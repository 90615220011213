import { registerLookup } from "@redriver/cinnamon";
import {
  getRegistrationItemTypes,
  getRegistrationItemWidths,
  getRegistrationEventDetails,
  getTravelProviderInfo,
} from "./actions";

export const registrationItemTypesLookup = registerLookup(
  "registrationItemTypesLookup",
  getRegistrationItemTypes
);

export const registrationItemWidthsLookup = registerLookup(
  "registrationItemWidthsLookup",
  getRegistrationItemWidths
);

export const registrationEventDetailsLookup = registerLookup(
  "registrationEventDetailsLookup",
  getRegistrationEventDetails
);

export const travelProviderInfo = registerLookup(
  "travelProviderInfoLookup",
  getTravelProviderInfo
);
