import React, { useState } from "react";
import { Form, ModalBuilder, Modal, useLookup } from "@redriver/cinnamon";
import { YesNoRadioGroup } from "components/forms";
import { FloatingEditButton } from "components/buttons";
import {
  registrationEventDetailsLookup,
  registrationItemTypesLookup,
  registrationItemWidthsLookup,
} from "./lookups";
import {
  EventFormat,
  RegistrationItemTypes,
  RegistrationItemWidths,
} from "constants/enums";
import { editItem, deleteItem } from "./actions";
import { ThreeButtonModal } from "components/modals";
import { useSelector } from "react-redux";
import { getHasPublishedRegistration } from "./selectors";
import { useUserType } from "components/auth";

const EditRegistrationItemModal = ({
  item,
  as: As = FloatingEditButton,
  eventId,
  groupId,
  onSubmitted,
  ...props
}) => {
  const isPublished = useSelector(getHasPublishedRegistration);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const isFullyCustomisable = item?.isCustomItem;
  const { isExternal } = useUserType();
  const [{ response }] = useLookup(registrationEventDetailsLookup, {
    eventId,
    isExternal,
  });

  const isVirtualAttendance = response?.format === EventFormat.Virtual;

  return (
    <ModalBuilder
      withForm
      initialData={{
        label:
          item?.type === RegistrationItemTypes.AttendanceDates &&
          isVirtualAttendance
            ? "Please indicate which dates you will participate"
            : item?.label,
        subHeader: !isVirtualAttendance ? item?.subHeading : null,
        info: item?.infoText,
        type: item?.type || RegistrationItemTypes.TextInput,
        width: item?.width || RegistrationItemWidths.Full,
        required: !!item?.required,
        hidden: !!item?.hidden,
        mandatoryPriorEvent: !!item?.mandatoryPriorEvent,
        onInternalList: !!item?.onInternalList,
        onExternalList: !!item?.onExternalList,
        cleanse: !!item?.cleanse,
        cleanseAfterWeeks: item?.cleanseAfterWeeks,
      }}
      submitAction={editItem}
      submitParams={{ groupId, eventId, itemId: item.id }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => <As {...props} onClick={showModal} />}
      renderModal={(modalProps, formProps) => (
        <React.Fragment>
          <ThreeButtonModal
            {...modalProps}
            size="small"
            header="Edit Registration Question"
            submitLabel="Update"
            cancelLabel="Cancel"
            otherSubmitLabel="Delete"
            otherSubmitIcon="trash"
            onOtherSubmit={
              item?.isCustomItem && !isPublished
                ? () => {
                    setConfirmDelete(true);
                    return true;
                  }
                : null
            }
          >
            <Form {...formProps} className="edit-registration-item">
              <Form.Input field="label" label="Question text" fluid required />
              <Form.Input
                field="subHeader"
                label="Question sub heading"
                fluid
              />
              <Form.Input
                field="info"
                label="Text to appear in the info icon for this question"
                fluid
              />
              <Form.Group widths={2}>
                {isFullyCustomisable ? (
                  <Form.Dropdown
                    field="type"
                    label="What type of answer does this question require?"
                    fluid
                    required
                    lookup={registrationItemTypesLookup}
                    disabled={isPublished}
                  />
                ) : (
                  <Form.Field label="What type of answer does this question require?">
                    <span>{item.typeDescription}</span>
                  </Form.Field>
                )}
                {isFullyCustomisable ? (
                  <Form.Dropdown
                    field="width"
                    label="What width within a row does this question take up?"
                    fluid
                    required
                    lookup={registrationItemWidthsLookup}
                    disabled={isPublished}
                  />
                ) : (
                  <Form.Field label="What width within a row does this question take up?">
                    <span>{item.width}</span>
                  </Form.Field>
                )}
              </Form.Group>
              <Form.Group widths={2}>
                <YesNoRadioGroup
                  field="required"
                  label="Is this mandatory to submit the form?"
                  disabled={isPublished}
                />

                <YesNoRadioGroup
                  field="mandatoryPriorEvent"
                  label="Is this mandatory prior to the event?"
                  disabled={isPublished}
                />
              </Form.Group>

              {/* <Form.Group widths={2}>
                    <YesNoRadioGroup
                      field="onInternalList"
                      label="Show on internal participants list?"
                      disabled={isPublished}
                    />

                    <YesNoRadioGroup
                      field="onExternalList"
                      label="Show on external participants list?"
                      disabled={isPublished}
                    />
                  </Form.Group> */}

              <Form.Group widths={2}>
                <YesNoRadioGroup
                  field="cleanse"
                  label="Cleanse data?"
                  disabled={isPublished || !isFullyCustomisable}
                />

                <YesNoRadioGroup
                  field="hidden"
                  label="Hide during registration process?"
                  disabled={isPublished || !item.canHide}
                />
              </Form.Group>

              <Form.Group widths={2}>
                <Form.If condition={({ fields }) => fields.cleanse}>
                  <Form.Field className="weeks-selector">
                    <Form.Numeric
                      required
                      field="cleanseAfterWeeks"
                      label="Cleanse data after"
                      minValue={1}
                      decimalPlaces={0}
                      subLabel="Week(s)"
                      subLabelPosition="right"
                      width="12em"
                      disabled={isPublished || !isFullyCustomisable}
                    />
                  </Form.Field>
                </Form.If>
              </Form.Group>
            </Form>
          </ThreeButtonModal>
          <ModalBuilder
            visible={!!confirmDelete}
            submitAction={deleteItem}
            submitParams={{ groupId, eventId, itemId: item.id }}
            onSubmitted={onSubmitted}
            onCancelled={() => setConfirmDelete(null)}
            renderModal={(modalProps) => (
              <Modal.Confirmation
                {...modalProps}
                header="Delete Registration Question"
              >
                <p>
                  Are you sure that you wish to delete this registration
                  question?
                </p>
              </Modal.Confirmation>
            )}
          />
        </React.Fragment>
      )}
    />
  );
};

export default EditRegistrationItemModal;
