import React from "react";
import classNames from "classnames";
import { Label } from "semantic-ui-react";

const ResourceTileAction = ({ icon, className, children, onClick }) => (
  <Label
    basic
    icon={icon}
    onClick={onClick}
    content={children}
    className={classNames("resource-tile-action", className)}
  />
);

export default ResourceTileAction;
