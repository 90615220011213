import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "PROFILE/EDIT_PRIVACY_SETTINGS";

const ActionTypes = {
  UpdatePrivacySettings: `${NAMESPACE}/UPDATE_PRIVACY_SETTINGS`,
};

export const updatePrivacySettings = (profile) => {
  const request = {
    isLinkedInPublic: profile?.isLinkedInPublic,
    isTwitterPublic: profile?.isTwitterPublic,
    isThemesPublic: profile?.isThemesPublic,
    isTelephonePublic: profile?.isTelephonePublic,
    isMobilePhonePublic: profile?.isMobilePhonePublic,
    isEmailPublic: profile?.isEmailPublic,
    isBiographyPublic: profile?.isBiographyPublic,
    socialMedia: Array.isArray(profile?.otherSocialMedia)
      ? profile.otherSocialMedia.map((x) => ({
          itemId: x.id,
          isPublic: x.isPublic,
        }))
      : [],
    otherContactPoints: Array.isArray(profile?.contactPoints)
      ? profile.contactPoints.map((x) => ({
          itemId: x.id,
          isPublic: x.isPublic,
        }))
      : [],
  };
  return apiPut(ActionTypes.UpdatePrivacySettings, `profile/privacy`, request);
};
