import React from "react";
import { Label, Popup } from "semantic-ui-react";

const ROOM_MAX_CHARS = 25;

const EventRoomInfoLabel = ({ roomInfo, ...props }) => {
  let room = roomInfo;
  let roomExceedsMaxChars = false;

  if (!roomInfo) return null;

  if (roomInfo && roomInfo.length > ROOM_MAX_CHARS) {
    roomExceedsMaxChars = true;
    room = roomInfo.substring(0, ROOM_MAX_CHARS - 1);
  }

  return (
    <Popup
      inverted
      content={`Rooming information${
        roomExceedsMaxChars ? `\n${roomInfo}` : ""
      }`}
      trigger={
        <Label {...props} basic icon="home" content={room} size="large" />
      }
    />
  );
};

export default EventRoomInfoLabel;
