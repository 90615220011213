import React from "react";

const ProfileBackground = ({ image, children }) => (
  <div className="profile-background">
    <div
      className="profile-background-image"
      style={
        image
          ? {
              backgroundImage: `url('${image}')`,
            }
          : undefined
      }
    ></div>
    {children}
  </div>
);

export default ProfileBackground;
