import React from "react";
import { Message } from "semantic-ui-react";
import { YesNoRadioGroup } from "components/forms/fields";
import { CustomErrors } from "components/forms";

const ShouldUpdateFutureEventProfilesMessage = () => {
  const validateRequired = ({ fields }) => {
    if (
      fields.shouldUpdateFutureEventProfiles == undefined ||
      fields.shouldUpdateFutureEventProfiles == null
    ) {
      return ["This field is required"];
    }
    return [];
  };
  return (
    <Message warning style={{ display: "block" }}>
      <YesNoRadioGroup
        field="shouldUpdateFutureEventProfiles"
        label="Would you like to update your profile for your upcoming event with these changes?"
        showErrors={false}
        className="required"
      />
      <CustomErrors
        field="shouldUpdateFutureEventProfiles"
        triggerFields={["shouldUpdateFutureEventProfiles"]}
        validator={validateRequired}
      />
    </Message>
  );
};

export default ShouldUpdateFutureEventProfilesMessage;
