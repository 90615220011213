import React from "react";
import { Image } from "semantic-ui-react";
import ImagePlaceholder from "assets/placeholders/profile-image.png";
import classNames from "classnames";

const ProfileImage = ({ image, onClick, className }) => (
  <div className={classNames("profile-image", className)} onClick={onClick}>
    <Image src={image ?? ImagePlaceholder} circular />
  </div>
);

export default ProfileImage;
