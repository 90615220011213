import { useLookup } from "@redriver/cinnamon";
import classNames from "classnames";
import React from "react";
import { programmeThemeLookup } from "modules/lookups";

const ThemeLabel = ({ themeId, className, style = {}, ...props }) => {
  const [lookupData] = useLookup(programmeThemeLookup);
  const theme = (lookupData?.response || []).find((x) => x.value == themeId);
  return (
    <div
      className={classNames("theme-label", className)}
      style={{ borderColor: theme?.colour, ...style }}
      {...props}
    >
      {theme?.text}
    </div>
  );
};

export default ThemeLabel;
