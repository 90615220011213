import React from "react";
import { Form, ModalBuilder, SideColumn } from "@redriver/cinnamon";
import { createGroup } from "./actions";
import { Icon, Segment } from "semantic-ui-react";
import { ClickArea } from "components/buttons";
import { FormModal } from "components/modals";

const CreateNewGroup = ({ eventId, sectionId, onRefresh }) => {
  return (
    <Segment className="additional-section">
      <SideColumn
        renderColumn={() => <p className="title">Create New Section</p>}
      >
        <ModalBuilder
          submitAction={createGroup}
          submitParams={{ eventId, sectionId }}
          onSubmitted={onRefresh}
          withForm
          renderTrigger={(openModal) => (
            <ClickArea onClick={openModal}>
              <span>
                <Icon name="plus" />
                Add Additional Section
              </span>
            </ClickArea>
          )}
          renderModal={(modalProps, formProps) => (
            <FormModal
              {...modalProps}
              header="Create additional section"
              size="small"
            >
              <Form {...formProps}>
                <Form.Input field="name" label="Section Name" fluid required />
                <Form.TextArea field="description" label="Description" fluid />
              </Form>
            </FormModal>
          )}
        />
      </SideColumn>
    </Segment>
  );
};

export default CreateNewGroup;
